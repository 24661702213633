import React from "react";

function ActionButton({ icon, label, link }) {
    return (
        <div className="d-flex align-items-start">
            <a
                href={link}
                className="btn btn-sm px-3 py-2 border-0 btn-light active justify-content-center d-block"
            >
                {icon && <img src={icon} className="me-2" alt={label} />}
                {label}
            </a>
        </div>
    );
}

export default ActionButton;
