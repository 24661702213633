import React, { useState, useEffect, useRef } from "react";
import CrownSVG from "./assets/CrownSVG.jsx";
import GiftSVG from "./assets/GiftSVG.jsx";
import MedalSVG from "./assets/MedalSVG.jsx";
import BadgeSVG from "./assets/BadgeSVG.jsx";
import Profile16pxSVG from "./assets/Profile16pxSVG.jsx";
import { usePopper } from "react-popper";
import SendMessageSVG from "./assets/SendMessageSVG.jsx";
import ReusableModal from "../ReusableModal.jsx";
import { useSelector } from "react-redux";
import MedalsListInModal from "./MedalsListInModal.jsx";
import BadgesListInModal from "./BadgesListInModal.jsx";
import ShopModal from "./ShopModal/index.jsx";
import CloseSVG from "../Icons/CloseSVG.jsx";

function ContextMenu({
    user,
    isOpen,
    targetRef,
    inputRef,
    handleGratesClick,
    gratesInput = 0,
    handleChangeGratesAmount,
    selectedUser,
    extraTypes = "",
    setExtraTypes,
    handleAwardChooseButtonClick,
    awards = [],
    handleAwardRemove,
    handleAwardClickIfAlreadyHaveAward,
    handleGiftConfirmationClick,
    handleRemoveFromText,
    gifts,
}) {
    const [inputVisible, setInputVisible] = useState(false);
    const [enoughRedics, setEnoughRedics] = useState(false);
    const [inputValue, setInputValue] = useState(0);
    const [popperRef, setPopperRef] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const { styles, attributes } = usePopper(targetRef.current, popperRef, {
        placement: "bottom",
        modifiers: [
            { name: "arrow", options: { element: arrowElement } },
            { name: "offset", options: { offset: [0, 5] } },
        ],
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(isOpen);
    const { Shop, Badge, Medal } = useSelector(
        (state) => state.extraTypes.extraTypes
    );
    const [selectedAward, setSelectedAward] = useState(null);
    const [modalButtonDisabled, setModalButtonDisabled] = useState(true);
    const [shopModalOpen, setShopModalOpen] = useState(false);

    const handleChange = ({ target }) => {
        const value = target.value;
        const numericValue = parseInt(value, 10);

        // Проверяем, является ли введенное значение числом и находится ли оно в диапазоне от 0 до 2
        if (!isNaN(numericValue) && numericValue >= 1 && numericValue <= 2) {
            setInputValue(value);
        } else {
            // Если введенное значение не соответствует требованиям, можно очистить поле ввода или установить его в максимально допустимое значение
            setInputValue(2);
        }
    };

    useEffect(() => {
        if (user?.redik * 1 >= inputValue * 1) {
            setEnoughRedics(true);
        } else {
            setEnoughRedics(false);
        }
    }, [user, inputValue]);

    const handleGiftMenuClick = () => {
        setExtraTypes("gift");
        setIsContextMenuOpen(false);
        setShopModalOpen(true);
    };

    const handleAwardMenuClick = (type) => {
        setExtraTypes(type);
        const titleJSX = (
            <span>
                {`Выберите ${
                    type === "badge" ? "бейдж" : "медаль"
                } для пользователя `}
                <span className="text-primary">{selectedUser.name}</span>
            </span>
        );
        setModalTitle(titleJSX);
        if (type === "badge") {
            setModalContent(
                <BadgesListInModal
                    badges={Badge}
                    onBadgeClick={handleAwardSelect}
                />
            );
        } else if (type === "reward") {
            setModalContent(
                <MedalsListInModal
                    medals={Medal}
                    onMedalClick={handleAwardSelect}
                />
            );
        }

        if (!awards.length) {
            setIsModalOpen(true);
        } else {
            handleAwardClickIfAlreadyHaveAward(selectedUser);
        }

        setIsContextMenuOpen(false);
    };

    const handleAwardSelect = (selectedAward) => {
        setSelectedAward(selectedAward);
        setModalButtonDisabled(false);
    };

    function isUserAwarded() {
        return awards.find((a) => a.user.id === selectedUser?.id);
    }
    if (selectedUser?.name) {
        return (
            <>
                <div
                    className={`bs-popover-auto popover ${
                        isContextMenuOpen ? "show" : "d-none"
                    }`}
                    style={styles.popper}
                    {...attributes.popper}
                    ref={setPopperRef}
                >
                    <div
                        className="popover-arrow"
                        ref={setArrowElement}
                        style={styles.arrow}
                    ></div>
                    <div className="list-group inside border-0">
                        {(extraTypes === "" || extraTypes === "thanks") && (
                            <a
                                className="list-group-item d-flex align-items-center sk-bg-gray-2 gap-2 border-top-0 inside"
                                role="button"
                                onClick={() => {
                                    handleGratesClick(inputValue, selectedUser);
                                    setInputVisible(true);
                                }}
                            >
                                <CrownSVG />
                                {/* {inputVisible ? (
                                    <div className="input-group d-flex inside align-items-center justify-content-between">
                                        {!gratesInput || !gratesInput === 0 ? (
                                            <input
                                                className="inside form-control form-control-sm bg-gray-3 border-0"
                                                type="number"
                                                placeholder="Введите кол-во редиков"
                                                ref={inputRef}
                                                value={inputValue}
                                                onChange={handleChange}
                                            ></input>
                                        ) : (
                                            <button
                                                className="btn p-0 border-0 text-primary text-14 pe-4 inside"
                                                onClick={
                                                    handleChangeGratesAmount
                                                }
                                            >
                                                {gratesInput}
                                            </button>
                                        )}
                                        <SendMessageSVG
                                            user={selectedUser}
                                            handleClick={handleGratesClick}
                                            disabled={!enoughRedics}
                                            value={inputValue}
                                            gray={gratesInput}
                                        />
                                    </div>
                                ) : (
                                    <span className="inside">
                                        Поблагодарить
                                    </span>
                                )} */}
                                <span className="inside">Поблагодарить</span>
                            </a>
                        )}
                        {(extraTypes === "" || extraTypes === "gift") && (
                            <a
                                className="list-group-item d-flex align-items-center sk-bg-gray-2 gap-2 inside"
                                role="button"
                                onClick={handleGiftMenuClick}
                            >
                                <GiftSVG />
                                <span>Отправить подарок</span>
                            </a>
                        )}
                        {(extraTypes === "" || extraTypes === "reward") && (
                            <a
                                className="list-group-item d-flex align-items-center sk-bg-gray-2 gap-2 inside"
                                role="button"
                                onClick={
                                    !isUserAwarded()
                                        ? () => handleAwardMenuClick("reward")
                                        : () =>
                                              handleAwardRemove(
                                                  awards.find(
                                                      (a) =>
                                                          a.user.id ===
                                                          selectedUser.id
                                                  )
                                              )
                                }
                            >
                                <MedalSVG />
                                <span>
                                    {!isUserAwarded()
                                        ? "Наградить медалью"
                                        : "Удалить медаль"}
                                </span>
                            </a>
                        )}
                        {(extraTypes === "" ||
                            extraTypes === "bage" ||
                            extraTypes === "badge") && (
                            <a
                                className="list-group-item d-flex align-items-center sk-bg-gray-2 gap-2 inside"
                                role="button"
                                onClick={
                                    !isUserAwarded()
                                        ? () => handleAwardMenuClick("badge")
                                        : () =>
                                              handleAwardRemove(
                                                  awards.find(
                                                      (a) =>
                                                          a.user.id ===
                                                          selectedUser.id
                                                  )
                                              )
                                }
                            >
                                <BadgeSVG />
                                <span>
                                    {!isUserAwarded()
                                        ? "Выдать бейдж"
                                        : "Удалить бейдж"}
                                </span>
                            </a>
                        )}
                        <a
                            className="list-group-item d-flex align-items-center sk-bg-gray-2 gap-2 border-bottom-0 inside"
                            role="button"
                            href={"/user/" + selectedUser.id}
                        >
                            <Profile16pxSVG />
                            <span className="inside">Профиль</span>
                        </a>
                        <a
                            className="list-group-item d-flex align-items-center sk-bg-gray-2 gap-2 border-bottom-0 inside"
                            role="button"
                            onClick={(e) =>
                                handleRemoveFromText(e, selectedUser.id)
                            }
                        >
                            <CloseSVG transparent={true} />
                            <span className="inside">Удалить из текста</span>
                        </a>
                    </div>
                </div>
                {isModalOpen ? (
                    <ReusableModal
                        show={isModalOpen}
                        onClose={() => {
                            setIsModalOpen(false);
                            setIsContextMenuOpen(isOpen);
                            setExtraTypes("");
                        }}
                        content={modalContent}
                        title={modalTitle}
                        firstBtn={{
                            class: "btn btn-sm",
                            title: "Закрыть",
                            handleClick: () => {
                                setIsModalOpen(false),
                                    setIsContextMenuOpen(isOpen);
                            },
                        }}
                        secondBtn={{
                            class: "btn btn-sm btn-primary",
                            title: "Выбрать",
                            disabled: modalButtonDisabled,
                            handleClick: () => {
                                if (extraTypes === "reward") {
                                    handleAwardChooseButtonClick(
                                        selectedAward,
                                        selectedUser,
                                        inputValue,
                                        "reward"
                                    );
                                } else if (extraTypes === "badge") {
                                    handleAwardChooseButtonClick(
                                        selectedAward,
                                        selectedUser,
                                        inputValue,
                                        "badge"
                                    );
                                }

                                setIsModalOpen(false),
                                    setIsContextMenuOpen(isOpen);
                            },
                        }}
                        large={true}
                    />
                ) : (
                    ""
                )}
                {shopModalOpen && (
                    <ShopModal
                        Shop={Shop}
                        show={shopModalOpen}
                        inputRef={inputRef}
                        onClose={(result) => {
                            if (!result) {
                                setIsContextMenuOpen(isOpen);
                                setExtraTypes("");
                            } else {
                                setExtraTypes("gift");
                            }
                            setShopModalOpen(false);
                        }}
                        selectedUser={selectedUser}
                        handleGiftConfirmationClick={
                            handleGiftConfirmationClick
                        }
                        gifts={gifts}
                    />
                )}
            </>
        );
    } else {
        return null;
    }
}

export default ContextMenu;
