function LikeEventHandler({ data }) {
    // Формируем идентификатор элемента для количества лайков
    data.type = data.type.replace(/\\+/g, '_');

    if(data.type !== 'Stena') {
        const elementId = `Blade_App_Models_${data.type}-${data.id}-likes`;
        const iconId = `App_Models_${data.type}-${data.id}-likeIcon`; // Идентификатор для иконки лайка

        // Находим элементы на странице
        const likesElement = document.getElementById(elementId);
        const likeIcon = document.getElementById(iconId);


        const elementIdModal = `Modal_App_Models_${data.type}-${data.id}-likes`;
        const likesElementModal = document.getElementById(elementIdModal);

        const iconIdModal = `Modal_App_Models_${data.type}-${data.id}-likeIcon`; // Идентификатор для иконки лайка
        const likeIconModal = document.getElementById(iconId);
        if (likeIconModal) {
            // Находим path внутри SVG и обновляем его атрибут stroke
            const pathModal = likeIconModal.querySelector('path');
            if (pathModal) {
                path.setAttribute('stroke', data.isLikedByUser ? "#FF0000" : "#828282");
            }
        }

        if (likesElementModal) {
            // Обновляем количество лайков в зависимости от действия
            likesElementModal.textContent = data.like;
        }

        if (likesElement) {
            // Обновляем количество лайков в зависимости от действия
            likesElement.textContent = data.like;
        }

        if (likeIcon) {
            // Находим path внутри SVG и обновляем его атрибут stroke
            const path = likeIcon.querySelector('path');
            if (path) {
                path.setAttribute('stroke', data.isLikedByUser ? "#FF0000" : "#828282");
            }
        }
    }
}

export default LikeEventHandler;
