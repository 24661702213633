import React from "react";

function DropdownItem({ item }) {
    if (item.link) {
        return (
            <a key={item.id} className="dropdown-item" href={item.link}>
                {item.name}
            </a>
        );
    } else if (item.onclicks) {
        return (
            <a
                key={item.id}
                className="dropdown-item"
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    eval(item.onclicks); // Внимание: eval может быть небезопасен
                }}
            >
                {item.name}
            </a>
        );
    } else {
        return (
            <span key={item.id} className="dropdown-item text-muted">
                {item.name}
            </span>
        );
    }
}

export default DropdownItem;
