import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { formatRelative, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';

const DialogsList = ({ onSelectDialog, selectedDialogId }) => {
    const [dialogs, setDialogs] = useState([]);

    useEffect(() => {
        const fetchDialogs = async () => {
            try {
                const response = await axios.get('/chat/dialogs');
                setDialogs(response.data.dialogs);
            } catch (error) {
                console.error('Ошибка при загрузке диалогов:', error);
            }
        };

        // Первоначальная загрузка
        fetchDialogs();

        // Установка интервала для периодической проверки
        const intervalId = setInterval(fetchDialogs, 30000); // 60 секунд

        // Очистка интервала при размонтировании компонента
        return () => clearInterval(intervalId);
    }, []);

    const formatDate = (dateString) => {
        return formatRelative(parseISO(dateString), new Date(), { locale: ru });
    };

    return (
        <div className="list-group">
            {dialogs.map(dialog => (
                <button
                    key={dialog.id}
                    onClick={() => onSelectDialog(dialog)}
                    className={`list-group-item list-group-item-action ${selectedDialogId === dialog.id ? 'active' : ''}`}
                    data-dialog-id={dialog.id}
                >
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <img
                                src={dialog.lastMessage?.sender?.avatar?.thumbnail_url || '/img/sk-logo.svg'}
                                className="rounded-circle w-48 h-48 object-fit-cover"
                                alt="avatar"
                            />
                            <div>
                                <h5 className="mb-1">{dialog.name}</h5>
                                <p className="mb-1 text-muted">
                                    {dialog.lastMessage
                                        ? dialog.lastMessage.body.length > 30
                                            ? dialog.lastMessage.body.substring(0, 30) + '...'
                                            : dialog.lastMessage.body
                                        : "Нет сообщений"}
                                </p>
                            </div>
                        </div>
                        {dialog.lastMessage && (
                            <small className="text-muted">
                                {formatDate(dialog.lastMessage.created_at)}
                            </small>
                        )}
                    </div>
                </button>
            ))}
        </div>
    );
};

export default DialogsList;
