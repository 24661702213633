import { useDispatch, useSelector } from "react-redux";
import ShopList from "./PostField/ShopList";
import { useEffect, useState } from "react";
import { fetchExtraTypes } from "../redux/extraTypesSlice";
import { fetchCurrentUserId, fetchUsers } from "../redux/usersSlice";
import SearchSVG from "./Icons/SearchSVG";

const ShopWrapper = ({ activeCategory = 0 }) => {
    const { Shop } = useSelector((state) => state.extraTypes.extraTypes);
    // console.log("Shop", Shop);

    const currentUser = useSelector((state) => state.users?.currentUser);

    const dispatch = useDispatch();

    const [inputValue, setInputValue] = useState("");
    const [showAccessibleOnly, setShowAccessibleOnly] = useState(false);
    const [sorting, setSorting] = useState({ title: "", id: null });
    const [filtredItems, setFiltredItems] = useState([]);

    // console.log("activeCategory", activeCategory);

    async function fetchData() {
        await dispatch(fetchExtraTypes(true));
        await dispatch(fetchUsers(true));
        await dispatch(fetchCurrentUserId(true));
    }

    useEffect(() => {
        if (!Shop) {
            fetchData();
        } else {
            handleFilterAndSort();
        }
    }, [Shop, inputValue, showAccessibleOnly, sorting, activeCategory]);

    const handleFilterAndSort = () => {
        let filtered = Shop.filter((item) =>
            activeCategory * 1 ? item.cat * 1 === activeCategory * 1 : item
        );

        if (inputValue) {
            filtered = filtered.filter((item) =>
                item.name.toLowerCase().includes(inputValue.toLowerCase())
            );
        }

        if (showAccessibleOnly) {
            filtered = filtered.filter((item) => item.summ <= currentUser.meow);
        }

        if (sorting && sorting.id) {
            filtered = filtered.sort((a, b) => {
                if (sorting.id === "asc") {
                    return sorting.type === "name"
                        ? a.name.localeCompare(b.name)
                        : a.summ - b.summ;
                } else {
                    return sorting.type === "name"
                        ? b.name.localeCompare(a.name)
                        : b.summ - a.summ;
                }
            });
        }

        setFiltredItems(filtered);
    };

    return (
        <div>
            <div className="d-md-block d-none">
                <div className="d-flex flex-column gap-4">
                    <div className="d-flex gap-3 align-items-center">
                        <div className="input-group align-items-center">
                            <span className="z-999">
                                <button
                                    className="btn border-0 me-n5"
                                    type="button"
                                >
                                    <SearchSVG />
                                </button>
                            </span>
                            <input
                                type="text"
                                className="form-control ps-5 rounded bg-gray-2 border-gray-2"
                                placeholder="Поиск по МяуМаркету"
                                aria-label="Поиск по МяуМаркету"
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                            />
                        </div>
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="accessibleOnly"
                                checked={showAccessibleOnly}
                                onChange={() =>
                                    setShowAccessibleOnly(!showAccessibleOnly)
                                }
                            />
                            <label
                                className="form-check-label"
                                htmlFor="accessibleOnly"
                            >
                                Доступные мне
                            </label>
                        </div>
                        <div className="dropdown">
                            <button
                                className="btn text-muted text-nowrap p-0 border-0"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Сортировка:{" "}
                                <span className="text-lowercase">
                                    {sorting.title || "Без сортировки"}
                                </span>
                            </button>
                            <div className="dropdown-menu pe-5">
                                {[
                                    {
                                        id: "asc",
                                        title: "По названию (возрастание)",
                                        type: "name",
                                    },
                                    {
                                        id: "desc",
                                        title: "По названию (убывание)",
                                        type: "name",
                                    },
                                    {
                                        id: "asc",
                                        title: "По цене (возрастание)",
                                        type: "price",
                                    },
                                    {
                                        id: "desc",
                                        title: "По цене (убывание)",
                                        type: "price",
                                    },
                                ].map((item) => (
                                    <li key={item.id + item.type}>
                                        <a
                                            className="dropdown-item"
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSorting(item);
                                            }}
                                        >
                                            {item.title}
                                        </a>
                                    </li>
                                ))}
                            </div>
                        </div>
                    </div>
                    <ShopList shopList={filtredItems} />
                </div>
            </div>
        </div>
    );
};

export default ShopWrapper;
