import { useDispatch, useSelector } from "react-redux";
import ShopList from "./PostField/ShopList";
import { useEffect } from "react";
import { fetchExtraTypes } from "../redux/extraTypesSlice";
import { fetchCurrentUserId, fetchUsers } from "../redux/usersSlice";

const ShopWrapper = ({ activeCategory }) => {
    const { Shop } = useSelector((state) => state.extraTypes.extraTypes);
    const dispatch = useDispatch();

    console.log("activeCategory", activeCategory);

    async function fetchData() {
        await dispatch(fetchExtraTypes(true));
        await dispatch(fetchUsers(true));
        await dispatch(fetchCurrentUserId(true));
    }

    useEffect(() => {
        if (!Shop) {
            fetchData();
        }
    }, [Shop]);

    return <ShopList shopList={Shop} />;
};

export default ShopWrapper;
