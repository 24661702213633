import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axios/middleware.js";

// Создаем асинхронное действие для получения пользователей
export const fetchUsers = createAsyncThunk(
    "users/fetchUsers",
    async (fetchFlagURL) => {
        if (fetchFlagURL) {
            try {
                const response = await axios.get("/users/json");
                const users = response.data;
                const usersWithCorrectImgLinks = users.map((user) => {
                    if (user.avatar) {
                        if (
                            user.avatar.original_url &&
                            user.avatar.original_url[0] !== "/"
                        ) {
                            user.avatar.original_url = `/${user.avatar.original_url}`;
                        }
                        if (
                            user.avatar.medium_url &&
                            user.avatar.medium_url[0] !== "/"
                        ) {
                            user.avatar.medium_url = `/${user.avatar.medium_url}`;
                        }
                        if (
                            user.avatar.thumbnail_url &&
                            user.avatar.thumbnail_url[0] !== "/"
                        ) {
                            user.avatar.thumbnail_url = `/${user.avatar.thumbnail_url}`;
                        }
                    }
                    return user;
                });
                return usersWithCorrectImgLinks;
            } catch (error) {
                throw error;
            }
        }
    }
);

export const fetchCurrentUserId = createAsyncThunk(
    "users/fetchCurrentUserId",
    async (fetchFlagURL) => {
        if (fetchFlagURL) {
            try {
                const response = await fetch("/get-current-user-id");
                const { userId } = await response.json();
                return userId;
            } catch (error) {
                throw error;
            }
        }
    }
);

const usersSlice = createSlice({
    name: "users",
    initialState: {
        users: [],
        working_users: [],
        loading: false,
        error: null,
        currentUser: {},
    },
    reducers: {
        setOnlineUsers: (state, action) => {
            const usersIds = action.payload;
            state.users = state.users.map((user) => {
                if (usersIds.includes(user.id)) {
                    user.last_active_at = JSON.stringify(new Date());
                }
                return user;
            });
            state.working_users = state.users.filter(
                (user) => user.role_id * 1 !== 3
            );
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.users = action.payload;
                state.working_users = action.payload.filter(
                    (user) => user.role_id * 1 !== 3
                );
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchCurrentUserId.fulfilled, (state, action) => {
                state.loading = false;
                state.currentUser = state.users.find((user) => {
                    return user.id * 1 === action.payload * 1;
                });
            })
            .addCase(fetchCurrentUserId.rejected, (state, action) => {
                state.loading = false;
                console.error(
                    "Failed to fetch current user's ID:",
                    action.error.message
                );
            });
    },
});

export const { setOnlineUsers } = usersSlice.actions;

export default usersSlice.reducer;
