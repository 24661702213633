import PinIconSVG from "../Icons/PinIconSVG";

function PinButton({ handlePinClick, isPinned }) {
    return (
        <div>
            <div
                role="button"
                className={`like-button d-flex align-items-center gap-1 sk-bg-gray-2 rounded px-2 py-1`}
                onClick={handlePinClick}
            >
                <div>
                    <PinIconSVG active={isPinned} />
                </div>
            </div>
        </div>
    );
}

export default PinButton;
