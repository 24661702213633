import MedalCard from "./MedalCard";
import ItemListInModal from "./ItemListInModal";

const MedalsListInModal = ({
    onMedalClick,
    onBtnClick,
    isSecondStepRequired,
    userId,
    action,
}) => (
    <ItemListInModal
        itemsSelector={(state) => state?.extraTypes?.extraTypes?.Medal}
        ItemCard={({ item, onItemClick, selectedItemId }) => (
            <MedalCard
                medal={item}
                onMedalClick={onItemClick}
                selectedMedalId={selectedItemId}
            />
        )}
        onItemClick={onMedalClick}
        isSecondStepRequired={isSecondStepRequired}
        onActionClick={onBtnClick}
        userId={userId}
        action={action}
    />
);

export default MedalsListInModal;
