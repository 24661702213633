// store/slices/postFieldSlice.js
import { createSlice } from "@reduxjs/toolkit";

const postFieldSlice = createSlice({
    name: "postField",
    initialState: {
        data: null, // Начальное состояние
    },
    reducers: {
        setData(state, action) {
            state.data = action.payload;
        },
    },
});

export const { setData } = postFieldSlice.actions;
export default postFieldSlice.reducer;
