import React, { useState, useRef, useEffect } from "react";
import Stepper from "../Stepper";
import FirstStage from "./FirstStage";
import SecondStage from "./SecondStage";
import ThirdStage from "./ThirdStage";
import { useSelector } from "react-redux";

const ShopModal = ({
    show,
    onClose = () => {},
    inputRef,
    selectedUser,
    handleGiftConfirmationClick,
    gifts = [],
    action,
    isCommentForUserRequired = false,
}) => {
    const { Shop } = useSelector((state) => state.extraTypes.extraTypes);
    console.log("Shop", Shop);

    const [stage, setStage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(show);
    const [activeInputState, setActiveInputState] = useState(false);
    const modalBackdropRef = useRef(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [presentCostOfGifts, setPresentCostOfGifts] = useState(0);
    const [commentForUser, setCommentForUser] = useState("");
    const initialSteps = [
        {
            id: 1,
            title: "Выбор подарка",
            completed: true,
            className: "fs-6 ",
        },
        {
            id: 2,
            title: "Подтверждение",
            completed: false,
        },
        {
            id: 3,
            title: "Результат",
            completed: false,
        },
    ];

    const [steps, setSteps] = useState(initialSteps);

    useEffect(() => {
        if (gifts.length) {
            setPresentCostOfGifts(
                gifts.reduce((acc, item) => acc + item.totalSumm * 1, 0)
            );
        }
    }, [gifts, Shop]);

    useEffect(() => {
        setIsModalOpen(show);
    }, [show]);

    const closeModal = () => {
        setIsModalOpen(false);
        onClose();
    };

    const handleBackdropClick = (e) => {
        if (
            (e.target.nodeName !== "INPUT" ||
                e.target.nodeName !== "TEXTAREA") &&
            activeInputState
        ) {
            setActiveInputState(false);
        }
        if (modalBackdropRef.current === e.target) {
            closeModal();
        }
    };

    function handleItemSelect(item) {
        setSelectedItem(item);
        setStage(2);
    }

    function handleReturnToFirstStage() {
        setStage(1);
    }

    useEffect(() => {
        setSteps((prevState) => {
            return prevState.map((step) => {
                if (step.id <= stage) {
                    return { ...step, completed: true };
                } else {
                    return { ...step, completed: false };
                }
            });
        });
    }, [stage]);

    return (
        <>
            {isModalOpen && (
                <div
                    className="modal fade show d-block inside"
                    tabIndex="-1"
                    role="dialog"
                    onClick={handleBackdropClick}
                    ref={modalBackdropRef}
                >
                    <div
                        className="modal-dialog modal-dialog-scrollable modal-lg"
                        role="document"
                    >
                        <div className="modal-content">
                            <div className="d-flex p-md-4 p-2 pb-2 align-items-center">
                                <Stepper steps={steps} />

                                <button
                                    type="button"
                                    className="btn-close btn-close-sm d-none d-md-flex"
                                    aria-label="Close"
                                    onClick={closeModal}
                                ></button>
                            </div>

                            <div className="modal-body overflow-x-hidden p-4 pt-2">
                                {stage === 1 && (
                                    <FirstStage
                                        Shop={Shop}
                                        inputRef={inputRef}
                                        activeInputState={activeInputState}
                                        setActiveInputState={
                                            setActiveInputState
                                        }
                                        handleItemSelect={handleItemSelect}
                                        presentCostOfGifts={presentCostOfGifts}
                                    />
                                )}
                                {stage === 2 && (
                                    <SecondStage
                                        item={selectedItem}
                                        selectedUser={selectedUser}
                                        inputRef={inputRef}
                                        activeInputState={activeInputState}
                                        setActiveInputState={
                                            setActiveInputState
                                        }
                                        handleConfirm={(item) => {
                                            handleGiftConfirmationClick({
                                                ...item,
                                                action,
                                                userId: selectedUser.id,
                                                comment: commentForUser,
                                            });
                                            setSelectedItem(item);
                                            setStage(3);
                                        }}
                                        onReturnBtnClick={
                                            handleReturnToFirstStage
                                        }
                                        presentCostOfGifts={presentCostOfGifts}
                                        isCommentForUserRequired={
                                            isCommentForUserRequired
                                        }
                                        setCommentForUser={setCommentForUser}
                                        commentForUser={commentForUser}
                                    />
                                )}
                                {stage === 3 && (
                                    <ThirdStage
                                        item={selectedItem}
                                        selectedUser={selectedUser}
                                        onClose={onClose}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isModalOpen && <div className="modal-backdrop fade show"></div>}
        </>
    );
};

export default ShopModal;
