import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTransition, animated } from 'react-spring';

const UserRandom = () => {
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [phrases, setPhrases] = useState({});
    const [isAnimating, setIsAnimating] = useState(true);
    const [magicText, setMagicText] = useState('Кошка Каролина поможет вам с выбором...');
    const [magicTextIndex, setMagicTextIndex] = useState(0);
    const [isDataLoaded, setIsDataLoaded] = useState(false); // новое состояние для загрузки данных

    // Функция для перемещения всех аватаров в случайные места
    const shuffleAvatars = () => {
        setUsers((currentUsers) =>
            currentUsers.map((user) => ({
                ...user,
                style: {
                    ...user.style,
                    transform: `translate(${Math.random() * 80}vw, ${Math.random() * 80}vh)`,
                    transition: 'transform 0.7s ease',
                },
            }))
        );
    };

    useEffect(() => {
        axios
            .get('/users/getRandomUsers')
            .then((response) => {
                setUsers(
                    response.data.allUsers.map((user) => ({
                        ...user,
                        style: {
                            position: 'absolute',
                            transform: 'translate(0, 0)', // Начальная позиция
                            opacity: 1,
                            zIndex: `${Math.floor(Math.random() * 100)}`,
                        },
                    }))
                );
                setSelectedUsers(response.data.selectedUsers);
                setPhrases(response.data.phrases);
                setIsDataLoaded(true); // Данные загружены
            })
            .catch((error) => {
                console.error('ну в рот мне ноги...', error);
            });

        // Устанавливаем флаг isAnimating в false после 9 секунд
        const timeoutId = setTimeout(() => setIsAnimating(false), 6000);
        return () => clearTimeout(timeoutId);
    }, []);

    // Перемешиваем аватары каждые 700 мс
    useEffect(() => {
        if (isDataLoaded) {
            const intervalId = setInterval(shuffleAvatars, 700);
            return () => clearInterval(intervalId);
        }
    }, [isDataLoaded]); // Перезапускаем интервал только после загрузки данных

    const transitions = useTransition(isAnimating ? [] : selectedUsers, {
        from: { transform: 'translate3d(0,-60%,0)', opacity: 0 },
        enter: { transform: 'translate3d(0,0,0)', opacity: 1 },
        leave: { transform: 'translate3d(0,60%,0)', opacity: 0 },
        unique: true,
        config: { tension: 200, friction: 20 },
        trail: 50,
    });

    // Функция для анимации текста "Magic рандома..." по буквам
    const animateText = () => {
        if (magicTextIndex < magicText.length) {
            setMagicTextIndex((prevIndex) => prevIndex + 1);
        } else {
            setIsAnimating(false);
        }
    };

    useEffect(() => {
        const textIntervalId = setInterval(animateText, 100); // Измените интервал по желанию
        return () => clearInterval(textIntervalId);
    }, []);

    return (
        <div className="col-12">
            <div className="row">
                {isAnimating ? (
                    <div className="col-12 text-center mb-2 bg-white">
                        <h1>
                            {magicText.slice(0, magicTextIndex)}
                        </h1>
                        {users.map((user) => (
                            <animated.div key={user.id} id={`avatar-${user.id}`} className="avatar-shuffle" style={user.style}>
                                {user.avatar && user.avatar.thumbnail_url && (
                                    <img
                                        src={`/${user.avatar.thumbnail_url}`}
                                        alt={user.name}
                                        style={{
                                            width: '50px',
                                            height: '50px',
                                            borderRadius: '50%',
                                            objectFit: 'cover',
                                        }}
                                    />
                                )}
                            </animated.div>
                        ))}
                    </div>
                ) : (
                    transitions((style, user) => (
                        <animated.div className="col-6 mb-2" style={{ ...style }} key={user.id}>
                            <div className="card p-2 mb-12 mb-2">
                                <div className="card-body d-flex align-items-center">
                                    {user.avatar ? (
                                        <img
                                            src={`/${user.avatar}`}
                                            alt={user.name}
                                            className="rounded-circle img-fluid me-2"
                                            style={{
                                                width: '60px',
                                                height: '60px',
                                                objectFit: 'cover',
                                            }}
                                        />
                                    ) : (
                                        <div
                                            style={{
                                                width: '60px',
                                                height: '60px',
                                                marginRight: '10px',
                                                flexShrink: 0,
                                                backgroundColor: user.generateColorFromId || '#ccc',
                                            }}
                                            className="rounded-circle text-white d-flex align-items-center justify-content-center me-2"
                                        >
                                            {user.short_name || '??'}
                                        </div>
                                    )}
                                    <div>
                                        <a
                                            className="link-underline link-underline-opacity-0 link-secondary link-underline-opacity-100-hover"
                                            href={`/user/${user.id}`}
                                        >
                                            {user.name}
                                        </a>
                                        {phrases[user.id] && (
                                            <p className="card-body text">Вы можете написать, например: {phrases[user.id]}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </animated.div>
                    ))
                )}
            </div>
        </div>
    );
};

export default UserRandom;
