export const links = {
    profile: [
      { id: 1, name: "Профиль", link: "/profile" },
      { id: 2, name: "Файлы", link: "/Attachment" },
      { id: 3, name: "Транзакции", link: "/user/my/transactions" },
      { id: 4, name: "Выход", onclicks: "event.preventDefault(); document.getElementById('logout-form').submit();" },
    ],
    notifications: [
      { id: 1, name: "Приказы", link: "/orders" },
      { id: 2, name: "Дайджесты", link: "/digests" },
      { id: 3, name: "Объявления", link: "/announcements" },
    ],
    education: [
      { id: 1, name: "Полезные материалы", link: "/Training" },
      { id: 2, name: "Мероприятия", link: "/activities" },
      { id: 3, name: "Библиотека", link: "/library" },

    ],
    employeeServices: [
      { id: 1, name: "Видеоконференция", link: "/conference" },
      { id: 2, name: "Прямая линия", link: "/services?name=Прямая линия" },
      { id: 3, name: "Бот Кошка Каролина", link: "/services?name=Бот Кошка Каролина" },
      { id: 4, name: "Линия доверия", link: "/services?name=Линия доверия" },
      { id: 5, name: "МяуМаркет", link: "/shop" },
    ],
  };
