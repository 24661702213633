import React, { useState, useEffect, useRef } from "react";
import SmileSVG from "./assets/SmileSVG.jsx";
import DogSVG from "./assets/DogSVG.jsx";
import TextSVG from "./assets/TextSVG.jsx";
import ContextMenu from "./ContextMenu.jsx";
import ContentEditable from "react-contenteditable";
import RedikSVG from "./assets/RedikSVG.jsx";
import ReactDOMServer from "react-dom/server";
import FileUploadComponent from "../Stena/FileUploadComponent.jsx";
import FilePreview from "../Stena/FilePreview.jsx";
import axios from "axios";
import SmilesPopover from "../SmilesPopover.jsx";
import { useDispatch, useSelector } from "react-redux";
import UserLogo from "../Stena/UserLogo.jsx";
import SuggestionsList from "./SuggestionsList.jsx";
import AwardsList from "./AwardsList.jsx";
import GiftsList from "./GiftsList.jsx";
import ReusableModal from "../ReusableModal.jsx";
import BadgeComponent from "../Stena/BadgeComponent.jsx";
import { editPost } from "../../redux/stenasSlice.js";
import PinButton from "../Stena/PinButton.jsx";

function PostField({}) {
    const data = useSelector((state) => state.postField.data);
    console.log("data in PostField", data);

    const dispatch = useDispatch();

    const [selectedFiles, setSelectedFiles] = useState(data?.attachments || []);
    const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(data?.body || "");
    const [showSuggestions, setShowSuggestions] = useState({ show: false });
    const inputRef = useRef(null);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [mentionedUsers, setMentionedUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(false);
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
    const [gratesInput, setGratesInput] = useState("");
    const targetRef = useRef(null);
    const smileTargetRef = useRef(null);
    const [redicsForUsers, setRedicsForUsers] = useState({});
    const [extraTypes, setExtraTypes] = useState("");
    const [awardsForUsers, setAwardsForUsers] = useState([]);
    const [giftsForUsers, setGiftsForUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState({});
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [savedSelection, setSavedSelection] = useState(null);
    const [isPinned, setIsPinned] = useState(0);

    const currentUserfromRedux = useSelector(
        (state) => state.users?.currentUser
    );

    useEffect(() => {
        if (data?.body) {
            setInputValue(data?.body);
        }
        if (data?.attachments?.length) {
            setSelectedFiles(data.attachments);
        }
        if (data?.body || data?.attachments?.length) {
            setIsEditing(true);
        }
    }, [data]);

    useEffect(() => {
        if (currentUserfromRedux?.name) {
            setCurrentUser(currentUserfromRedux);
        }
    }, [currentUserfromRedux]);

    const users = useSelector((state) => state.users?.working_users);

    useEffect(() => {
        if (users.length) {
            setFilteredUsers(users);
        }
    }, [users]);

    const token = document.head.querySelector(
        'meta[name="csrf-token"]'
    ).content;

    const resetStateToDefaults = () => {
        setSelectedFiles([]);
        setIsEmojiPickerOpen(false);
        setIsEditing(false);
        setInputValue("");
        setShowSuggestions({ show: false });
        setFilteredUsers([]);
        setMentionedUsers([]);
        setSelectedUser(false);
        setIsContextMenuOpen(false);
        setGratesInput("");
        setRedicsForUsers({});
        setExtraTypes("");
        setAwardsForUsers([]);
        setGiftsForUsers([]);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        processFiles([...e.dataTransfer.files]);
    };

    useEffect(() => {
        // console.log(inputRef.current);
        if (selectedUser?.id) {
            if (showSuggestions.show) {
                setShowSuggestions({ show: false });
            }
            setIsContextMenuOpen(true);
        } else {
            setIsContextMenuOpen(false);
            inputRef.current?.focus();
        }
    }, [selectedUser]);

    useEffect(() => {
        if (showSuggestions?.show) {
            setIsContextMenuOpen(false);
        }
    }, [showSuggestions]);

    const handlePaste = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const files = clipboardData.files;
        if (files.length > 0) {
            processFiles([...files]);
        }
    };

    const handleInputPaste = (e) => {
        e.preventDefault();
        const clipboardData = e.clipboardData || window.clipboardData;
        const textData = clipboardData.getData("text/plain");

        if (textData) {
            // Вы можете обработать и вставить текст в ваш компонент
            // Получаем выделение (selection) из текущего элемента contenteditable
            const selection = window.getSelection();
            if (!selection.rangeCount) return;

            // Получаем range из текущего выделения
            const range = selection.getRangeAt(0);

            // Удаляем содержимое текущего выделения
            range.deleteContents();

            // Создаем новый узел с вставляемым текстом
            const textNode = document.createTextNode(textData);

            // Вставляем новый узел в место выделения
            range.insertNode(textNode);

            // Перемещаем курсор после вставленного текста
            range.setStartAfter(textNode);
            range.setEndAfter(textNode);

            // Обновляем выделение
            selection.removeAllRanges();
            selection.addRange(range);
        } else {
            handlePaste(e);
        }
    };

    const handleFileChange = (e) => {
        const postId = e.target.dataset.postid;
        const commentId = e.target.dataset.commentid || null;
        for (const file of e.target.files) {
            file.postId = postId;
            if (commentId) {
                file.commentId = commentId;
            }
        }
        processFiles([...e.target.files]);
        e.target.value = null;
    };
    const processFiles = (files) => {
        setSelectedFiles((prevFiles) => {
            if (prevFiles.length) {
                return [...prevFiles, ...files];
            } else return files;
        });
    };
    function handleRemoveFile(file) {
        const updatedFiles = selectedFiles.filter(
            (f) =>
                f.name !== file.name &&
                f.size !== file.size &&
                f.lastModified !== file.lastModified
        );

        setSelectedFiles(updatedFiles);
    }
    const handleSpanClick = () => {
        setIsEditing(true);
        setTimeout(() => inputRef.current && inputRef.current.focus(), 0);
    };

    const handleUserSelect = (user) => {
        // добавляем пользователя в список выбранных
        const updatedMentionedUsers = [...mentionedUsers, user];
        setMentionedUsers(updatedMentionedUsers);

        // исключаем пользователя из списка предложений
        const newFilteredUsers = getFilteredUsers(updatedMentionedUsers);
        setFilteredUsers(newFilteredUsers);

        const atIndex = inputValue.lastIndexOf("@");
        if (atIndex !== -1) {
            const textBeforeAt = inputValue.substring(0, atIndex);
            const redikSVGHtml = ReactDOMServer.renderToStaticMarkup(
                <RedikSVG />
            );
            const newValue = `${textBeforeAt} <button class="btn btn-sm p-0 fs-6 border-0 text-start align-top"  contenteditable="false"style="color:#2B8DFF" data-id='${
                user.id
            }'>@${user.name} <span class="redics-count text-primary">${
                redicsForUsers[user.id] || ""
            }</span> ${
                redicsForUsers[user.id] ? redikSVGHtml : ""
            }</button>&nbsp;`;

            setInputValue(newValue);

            setMentionedUsers([...mentionedUsers, user]);
            setShowSuggestions({ show: false });

            setTimeout(() => {
                if (inputRef.current) {
                    const range = document.createRange();
                    const selection = window.getSelection();
                    range.selectNodeContents(inputRef.current);
                    range.collapse(false); // false означает, что диапазон схлопывается к конечной точке, то есть к концу содержимого
                    selection.removeAllRanges();
                    selection.addRange(range);
                    inputRef.current.focus();
                }
                if (redicsForUsers[user.id]) {
                    handleGratesClick(redicsForUsers[user.id]);
                }
            }, 0);
        }
    };

    const handleAtSignInsertion = (value) => {
        // setShowSuggestions({ show: true, handleClick: handleUserSelect });
        setShowSuggestions({ show: true });
        const atIndex = value.lastIndexOf("@");
        const filterTerm = value.substring(atIndex + 1).toLowerCase();
        const newFilteredUsers = getFilteredUsers(mentionedUsers, filterTerm);
        if (!newFilteredUsers.length) {
            setShowSuggestions({ show: false });
        }
        setFilteredUsers(newFilteredUsers);
        setInputValue(value);

        // Установите курсор в конец ContentEditable после обновления значения
        setTimeout(() => {
            if (inputRef.current) {
                const inputElement = inputRef.current;
                inputElement.focus();
                const range = document.createRange();
                const selection = window.getSelection();
                range.selectNodeContents(inputElement);
                range.collapse(false);
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }, 0);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            // console.log(event.target);
            // console.log(inputRef);

            if (!event.target.closest(".inside")) {
                if (showSuggestions.show) {
                    setShowSuggestions({ show: false });
                }
                if (selectedUser) {
                    setSelectedUser(false);
                }
                setSelectedUser(false);
            } else {
                if (event.target.closest(".contenteditable")) {
                    //если был клик внутри contenteditable, но не по пользователю
                    if (!inputRef.current && !event.target.dataset?.id) {
                        inputRef.current =
                            event.target.closest(".contenteditable");
                        if (showSuggestions.show) {
                            setShowSuggestions({ show: false });
                        }
                        if (selectedUser) {
                            setSelectedUser(false);
                        }
                    }
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [inputRef]);

    const handlePopoverMouseLeave = () => {
        setIsEmojiPickerOpen(false);
    };

    const getFilteredUsers = (mentionedUsers, filterTerm) => {
        if (filterTerm) {
            return users.filter(
                (user) =>
                    user.name.toLowerCase().includes(filterTerm) &&
                    !mentionedUsers.includes(user)
            );
        }
        return users.filter(
            (filteredUser) => !mentionedUsers.includes(filteredUser)
        );
    };

    const handleUserClick = (e) => {
        // console.log("here in handleUserClick", e);
        e.stopPropagation();
        const { target } = e;
        const userId = target.dataset.id;
        const user = users.find((user) => user.id * 1 === userId * 1);
        if (user && target) {
            targetRef.current = target;
            setSelectedUser(user);
        }
    };

    function handleGratesClick(redicsCount, selectedUser) {
        if (selectedUser && selectedUser.id) {
            const userId = selectedUser.id;

            // Создаем React-элемент для иконки RedikSVG
            const redikSVGElement = <RedikSVG />;

            // Преобразуем его в строку HTML
            const redikSVGHtml =
                ReactDOMServer.renderToStaticMarkup(redikSVGElement);
            const regex = new RegExp(
                `(<button[^>]*data-id=['"]${userId}['"][^>]*>@${selectedUser.name} <span class="redics-count text-primary">)(\\d*)</span>(\\s*${redikSVGHtml})?`,
                "g"
            );

            let newValue = inputValue.replace(regex, (match, p1, p2, p3) => {
                if (redicsCount) {
                    return `${p1}${redicsCount}</span> ${redikSVGHtml}`;
                } else {
                    return p1;
                }
            });
            setInputValue(newValue);
            setRedicsForUsers((prev) => ({ ...prev, [userId]: redicsCount }));
        }
        setIsContextMenuOpen(false);
        setExtraTypes("thanks");
    }

    function handleAwardChooseButtonClick(
        selectedAward,
        selectedUser,
        text,
        type
    ) {
        if (selectedAward?.id && selectedUser?.id) {
            setAwardsForUsers((prev) => [
                ...prev,
                { ...selectedAward, user: selectedUser, comment: text },
            ]);
            const isAwardCommented = awardsForUsers.find((a) => a.comment);
            if (text && !isAwardCommented) {
                setInputValue(text);
            }
        }
        setIsContextMenuOpen(false);
        setSelectedUser(false);
        if (type) {
            setExtraTypes(type);
        }

        setFilteredUsers((prev) =>
            prev.filter((user) => user.id !== selectedUser.id)
        );
    }

    useEffect(() => {
        if (isContextMenuOpen) {
            inputRef.current = null;
        }
    }, [isContextMenuOpen]);

    function handleSmileIconClick({ target }) {
        if (showSuggestions.show) {
            setShowSuggestions({ show: false });
        }
        if (!isEmojiPickerOpen) {
            saveSelection();
            setIsEmojiPickerOpen(true);
        } else {
            setIsEmojiPickerOpen(false);
        }
        smileTargetRef.current = target;
    }

    function handleChangeGratesAmount() {
        setGratesInput("");
    }
    useEffect(() => {
        if (showSuggestions.show && isEmojiPickerOpen) {
            setIsEmojiPickerOpen(false);
        }
    }, [showSuggestions, isEmojiPickerOpen]);

    const containsOnlySpaces = (text) => {
        // Удаляем HTML теги, кроме тех, которые могут содержать видимый контент (например, img)
        const strippedText = text.replace(/<(?!img\b)[^>]+>/g, "");
        // Удаляем пустые <img> теги (если такие есть)
        const finalText = strippedText.replace(/<img\s*\/?>/g, "").trim();
        return /^\s*$/.test(finalText); // Проверяем, состоит ли строка только из пробелов
    };

    const handleChange = (event) => {
        if (showSuggestions.show) {
            setShowSuggestions({ show: false });
        }
        const value = event.target.value;
        const isSpaceOnly = containsOnlySpaces(value);
        // console.log("isSpaceOnly", isSpaceOnly);
        if (!isSpaceOnly) {
            setInputValue(value);
        } else {
            setInputValue("");
        }
        const atIndex = value.lastIndexOf("@");
        const atSpaceIndex = value.lastIndexOf(" ");
        if (atIndex !== -1 && atSpaceIndex < atIndex) {
            handleAtSignInsertion(value);
        } else if (atIndex === -1 && Object.keys(redicsForUsers).length) {
            //если нет собаки в тексте, но существуют выданные редики, то просто всегда делаем сброс редиков
            setRedicsForUsers({});
        }
        // if (!value.includes("@") && filteredUsers.length) {
        //     setFilteredUsers([]);
        //     setExtraTypes("");
        // }

        // Создаем новый список выбранных пользователей
        const updatedMentionedUsers = mentionedUsers.filter((user) => {
            // Создаем регулярное выражение для поиска имени пользователя в контенте
            const regex = new RegExp(`@${user.name}(\\s|<\/span>)`, "g");
            return value.match(regex);
        });

        // Обновляем список mentionedUsers, если он изменился
        if (updatedMentionedUsers.length !== mentionedUsers.length) {
            setMentionedUsers(updatedMentionedUsers);
        }
        saveSelection();
    };

    function formateBeforeSending(value) {
        let plainText = value
            .replace("<div>", "<br>")
            .replace(/<div>/g, "")
            .replace(/<\/div>/g, "<br>")
            .replace(
                /<button class="btn btn-sm p-0 fs-6 border-0 text-start align-top" contenteditable="false" style="color:#2B8DFF" data-id="/g,
                `<a class="text-primary link-underline link-underline-opacity-0" href="/user/`
            )
            .replace(/<\/button>/g, "</a>")
            .replace(/<script/g, "");

        // Удаляем пустые <br> в конце текста
        plainText = plainText.replace(/(<br>\s*)+$/, "");

        return plainText;
    }

    useEffect(() => {
        //отработка нажатия на клавишу ctrl+Enter
        const handleKeyDown = (event) => {
            if (isEditing && event.ctrlKey && event.key === "Enter") {
                const settings = JSON.parse(localStorage.getItem("settings"));
                const settingsChecked = settings?.find((s) => s.id === 6);
                if (settings && settingsChecked.checked) {
                    sendPost();
                }
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [inputValue, isEditing]);

    const sendPost = async () => {
        const content = formateBeforeSending(inputValue);
        const formData = new FormData();
        selectedFiles.forEach((file) => formData.append("files[]", file));

        const stenaDivs = document.getElementById("stena");
        const userId = stenaDivs ? stenaDivs.getAttribute("data-user") : null;
        if (userId) {
            formData.append("user_id_wall_post", userId);
        }

        const department_id = stenaDivs
            ? stenaDivs.getAttribute("data-departments")
            : null;
        if (department_id) {
            formData.append("department_id", department_id);
        }

        const user_mentions = mentionedUsers.map((user) => user.id).join(",");
        // Добавляем в formData
        formData.append("user_mentions", user_mentions);

        formData.append("body", content);
        formData.append("_token", token);
        formData.append("is_banner", isPinned);
        if (extraTypes === "") {
            formData.append("type", "");
        } else if (extraTypes === "thanks") {
            formData.append("type", "gratitude");
            formData.append("redik", JSON.stringify(redicsForUsers));
        } else if (extraTypes === "reward") {
            formData.append("type", "medal_awarded");
        } else if (extraTypes === "badge" || extraTypes === "bage") {
            formData.append("type", "badges");
        } else if (extraTypes === "gift") {
            formData.append("type", "shop");
        }
        const formatedItemsForUsers = {};
        if (awardsForUsers.length) {
            awardsForUsers.map((medal) => {
                return (formatedItemsForUsers[medal.user?.id] = medal.id);
            });
        } else if (giftsForUsers.length) {
            giftsForUsers.map((gift) => {
                return (formatedItemsForUsers[gift.user?.id] = gift.id);
            });
        }
        if (Object.keys(formatedItemsForUsers).length) {
            formData.append("redik", JSON.stringify(formatedItemsForUsers));
        }
        setIsLoading(true);
        if (!data?.id) {
            axios
                .post("/stenas/storeTestJS", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    // Обработайте успешный ответ от сервера, если необходимо
                    console.log("Сообщение успешно отправлено");
                    if (extraTypes === "gift") {
                        alert("На время тестирования мяукойны не списываются!");
                    }
                    resetStateToDefaults();
                })
                .catch((error) => {
                    // Обработайте ошибку, если отправка не удалась
                    console.error("Ошибка при отправке сообщения:", error);
                    setErrors((prevState) => [...prevState, error]);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            formData.append("id", data.id);
            const response = await dispatch(editPost({ formData }));
            console.log(response);

            if (response.payload.message === "Record updated successfully!") {
                console.log("Сообщение успешно отправлено");
                setIsLoading(false);
                resetStateToDefaults();
            }
        }
    };

    function handleCommentComplete(value) {
        console.log(value);
    }

    function removeAwardFromUser(item, type = "reward") {
        if (type === "reward" || type === "badge") {
            setAwardsForUsers((prevState) =>
                prevState.filter((m) => m?.user?.id !== item?.user?.id)
            );
        } else if (type === "gift") {
            setGiftsForUsers((prevState) =>
                prevState.filter((m) => m?.user?.id !== item?.user?.id)
            );
        }
    }

    useEffect(() => {
        if (!awardsForUsers.length && !giftsForUsers.length) {
            setExtraTypes("");
        }
    }, [awardsForUsers, giftsForUsers]);

    function handleAddMoreClick() {
        setShowSuggestions({
            show: true,
            type: "medal",
            handleClick: handleAddAwardClick,
        });
    }

    function handleAddAwardClick(selectedUser) {
        const presentAward = awardsForUsers[0];
        if (selectedUser?.id) {
            setAwardsForUsers((prev) => [
                ...prev,
                { ...presentAward, user: selectedUser },
            ]);
        }
        setSelectedUser(false);
        setFilteredUsers((prev) =>
            prev.filter((user) => user.id !== selectedUser.id)
        );
        setShowSuggestions({ show: false });
    }

    function handleGiftConfirmationClick(item) {
        // console.log(item);
        const newItem = { ...item, user: selectedUser };
        setGiftsForUsers((prev) => [...prev, newItem]);
        setExtraTypes("gift");
        setShowSuggestions({ show: false });
    }

    function handleRemoveFromText(e, id) {
        e.preventDefault();
        e.stopPropagation();
        // console.log(inputValue);
        const pattern = new RegExp(
            '<button[^>]*data-id="' + id + '"[^>]*>(.*?)</button>',
            "g"
        );
        const newInput = inputValue.replace(pattern, "");
        setInputValue(newInput);
        setIsContextMenuOpen(false);
    }

    const saveSelection = () => {
        const sel = window.getSelection();
        if (sel.rangeCount > 0) {
            const range = sel.getRangeAt(0);
            // Проверка, что выделение находится внутри текстового узла
            if (
                range.startContainer.nodeType === Node.TEXT_NODE &&
                range.endContainer.nodeType === Node.TEXT_NODE
            ) {
                setSavedSelection(range.cloneRange()); // Сохраняем копию текущего диапазона
            } else {
                // console.log("Selection is not inside a text node.");
            }
        }
    };

    const restoreSelection = () => {
        const sel = window.getSelection();
        if (savedSelection) {
            sel.removeAllRanges();
            sel.addRange(savedSelection);
        }
    };

    const onEmojiClick = (e) => {
        setIsEditing(true);
        insertEmojiAtCaret(e.imageUrl, e.emoji);
    };

    const insertEmojiAtCaret = (imageUrl, emoji) => {
        const img = document.createElement("img");
        img.src = imageUrl;
        img.alt = emoji;
        img.className = "w-20 h-20";
        restoreSelection();

        const sel = window.getSelection();
        if (sel.rangeCount > 0) {
            const range = sel.getRangeAt(0);

            range.insertNode(img);

            // Перемещение курсора после вставленного изображения
            range.setStartAfter(img);
            range.setEndAfter(img);
            sel.removeAllRanges();
            sel.addRange(range);
            // Обновление inputValue
            setInputValue(inputRef.current.innerHTML);
        }
    };

    function handlePinClick() {
        setIsPinned(!isPinned ? 1 : 0);
    }

    return (
        <>
            <div
                className={
                    "d-flex justify-content-between gap-3  p-md-4 p-2" +
                    (showSuggestions.show ? " pb-0" : "") +
                    (isEditing ? " flex-column" : " align-items-center")
                }
                onClick={handleSpanClick}
            >
                <div className="d-flex align-items-top gap-3 flex-grow-1">
                    {currentUser?.name ? (
                        <div className="d-flex">
                            <UserLogo user={currentUser} size="small" />
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="ms-1 text-primary d-inline inside flex-grow-1">
                        {extraTypes && awardsForUsers.length ? (
                            <AwardsList
                                awardsForUsers={awardsForUsers}
                                extraTypes={extraTypes}
                                removeAwardFromUser={removeAwardFromUser}
                                handleUserClick={handleUserClick}
                                handleAddMoreClick={handleAddMoreClick}
                            />
                        ) : (
                            ""
                        )}
                        {extraTypes === "gift" && giftsForUsers.length ? (
                            <GiftsList
                                items={giftsForUsers}
                                removeAwardFromUser={removeAwardFromUser}
                            />
                        ) : (
                            ""
                        )}
                        {isEditing ? (
                            <>
                                <div
                                    className="input-group d-flex align-items-center"
                                    style={{
                                        overflow: showSuggestions.show
                                            ? "hidden"
                                            : "visible",
                                        maxHeight: showSuggestions.show
                                            ? "20vh"
                                            : "none",
                                    }}
                                >
                                    <ContentEditable
                                        innerRef={inputRef}
                                        html={inputValue}
                                        onChange={handleChange}
                                        onClick={handleUserClick}
                                        onPaste={handleInputPaste}
                                        className="form-control border-0 ps-0 inside contenteditable"
                                        // onBlur={(e) => {
                                        //     if (isInputFocused) {
                                        //         inputFocusChange(e);
                                        //     }
                                        // }}
                                        // onFocus={(e) => {
                                        //     // console.log("focus");
                                        //     if (!isInputFocused) {
                                        //         inputFocusChange(e);
                                        //     }

                                        //     // handleUserClick(e);
                                        // }}
                                        onInput={saveSelection} // Сохраняем положение курсора при изменении содержимого
                                    />
                                </div>
                            </>
                        ) : (
                            <input
                                className={`form-control ps-0 border-0 ${
                                    window.innerWidth > 768 ? "" : "text-14"
                                }`}
                                placeholder={
                                    window.innerWidth > 768
                                        ? "Поделитесь чем-нибудь или поблагодарите кого-то"
                                        : "Поделитесь чем-нибудь"
                                }
                                onChange={handleChange}
                            />
                        )}
                    </div>

                    {isEditing ? (
                        <div className="d-flex gap-3">
                            <div className="2-icons d-flex gap-12 pt-1">
                                {extraTypes === "thanks" && (
                                    <BadgeComponent extraTypes={extraTypes} />
                                )}
                                <SmileSVG handleClick={handleSmileIconClick} />

                                <DogSVG
                                    handleClick={() =>
                                        handleAtSignInsertion(inputValue + " @")
                                    }
                                />
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>

                {isContextMenuOpen && (
                    <ContextMenu
                        user={currentUser}
                        selectedUser={selectedUser}
                        isOpen={isContextMenuOpen}
                        targetRef={targetRef}
                        inputRef={inputRef}
                        handleGratesClick={handleGratesClick}
                        gratesInput={gratesInput}
                        handleChangeGratesAmount={handleChangeGratesAmount}
                        extraTypes={extraTypes}
                        setExtraTypes={setExtraTypes}
                        handleAwardChooseButtonClick={
                            handleAwardChooseButtonClick
                        }
                        handleCommentComplete={handleCommentComplete}
                        awards={awardsForUsers}
                        handleAwardRemove={removeAwardFromUser}
                        awardInputValue={inputValue}
                        handleAwardClickIfAlreadyHaveAward={handleAddAwardClick}
                        handleGiftConfirmationClick={
                            handleGiftConfirmationClick
                        }
                        handleRemoveFromText={handleRemoveFromText}
                        gifts={giftsForUsers}
                    />
                )}
                {!showSuggestions.show ? (
                    <div className="d-flex justify-content-between align-items-center gap-2">
                        <div className="4-icons d-flex align-items-center gap-2">
                            <FileUploadComponent
                                handleDrop={handleDrop}
                                handlePaste={handlePaste}
                                handleFileChange={handleFileChange}
                                type="photo"
                                postId={null}
                                commentId={null}
                            />
                            <div className="d-none">
                                <TextSVG />
                            </div>
                            <FileUploadComponent
                                handleDrop={handleDrop}
                                handlePaste={handlePaste}
                                handleFileChange={handleFileChange}
                                type="all"
                                postId={null}
                                commentId={null}
                            />
                            <PinButton
                                handlePinClick={handlePinClick}
                                isPinned={isPinned}
                            />
                        </div>
                        {isEditing ? (
                            <div>
                                <button
                                    className={
                                        "btn btn-sm btn-primary" +
                                        (!inputValue ||
                                        /^[\s\u00A0]*$/.test(
                                            inputValue.replace(/&nbsp;/g, " ")
                                        ) ||
                                        isLoading
                                            ? " disabled"
                                            : "")
                                    }
                                    onClick={sendPost}
                                >
                                    {isLoading && (
                                        <span
                                            className="spinner-border spinner-border-sm me-1"
                                            aria-hidden="true"
                                        ></span>
                                    )}
                                    <span>
                                        {!isLoading
                                            ? "Отправить"
                                            : "Отправка..."}
                                    </span>
                                </button>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                ) : (
                    ""
                )}
            </div>

            {showSuggestions.show && filteredUsers.length ? (
                <SuggestionsList
                    filteredUsers={filteredUsers}
                    currentUser={currentUser}
                    handleUserSelect={
                        showSuggestions.handleClick || handleUserSelect
                    }
                />
            ) : (
                ""
            )}
            {selectedFiles.length && !showSuggestions.show ? (
                <div className="ms-4 mb-4">
                    <FilePreview
                        files={selectedFiles}
                        handleRemoveFile={handleRemoveFile}
                    />
                </div>
            ) : (
                ""
            )}
            {isEmojiPickerOpen && (
                <SmilesPopover
                    // onEmojiClick={(e) => {
                    //     setIsEditing(true);
                    //     setInputValue(
                    //         (prevValue) =>
                    //             prevValue +
                    //             `<img class="w-20 h-20" src="${e.imageUrl}" alt="${e.emoji}" />`
                    //     );
                    // }}
                    onEmojiClick={onEmojiClick}
                    target={smileTargetRef}
                    handlePopoverMouseLeave={handlePopoverMouseLeave}
                />
            )}
            {errors.length
                ? errors.map((error, index) => (
                      <ReusableModal
                          title="Ошибка"
                          content={error.message}
                          show={true}
                          key={index}
                          large={true}
                      />
                  ))
                : ""}
        </>
    );
}

export default PostField;
