import React, { useState } from "react";
import RedikSVG24Icon from "./RedikSVG24Icon";

const ThanksInModalComponent = ({
    userId,
    onBtnClick = () => {},
    action,
    onClose = () => {},
}) => {
    const [selectedBadge, setSelectedBadge] = useState(0);
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleBadgeSelect = (badge) => {
        setSelectedBadge(badge);
    };

    const handleSubmit = () => {
        setIsLoading(true);
        // alert(`Sent Thank You to ${userName} with badge ${selectedBadge}: ${message}`);
        onBtnClick({
            action,
            id: userId,
            comment: message,
            userId,
            value: selectedBadge,
        });
    };

    return (
        <>
            {/* <div className="d-flex mb-3 gap-2">
                <button
                    className="btn d-flex gap-2"
                    style={{
                        backgroundColor:
                            selectedBadge === 1 ? "#F4CB01" : "#F6F6F6",
                    }}
                    onClick={() => handleBadgeSelect(1)}
                >
                    <RedikSVG24Icon />
                    <span>1</span>
                </button>
                <button
                    className="btn d-flex gap-2"
                    style={{
                        backgroundColor:
                            selectedBadge === 2 ? "#F4CB01" : "#F6F6F6",
                    }}
                    onClick={() => handleBadgeSelect(2)}
                >
                    <RedikSVG24Icon />
                    <span>2</span>
                </button>
            </div> */}

            <textarea
                className="form-control mb-3 bg-gray-2"
                placeholder="За что вы благодарите?"
                rows="3"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            ></textarea>

            <div className="d-flex gap-2">
                <button className="btn btn-secondary" onClick={onClose}>
                    Отмена
                </button>
                {!isLoading ? (
                    <button className="btn btn-primary" onClick={handleSubmit}>
                        Отправить
                    </button>
                ) : (
                    <button className="btn btn-primary" disabled>
                        <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        Отправка...
                    </button>
                )}
            </div>
        </>
    );
};

export default ThanksInModalComponent;
