import React from "react";

function AccordionItem({ id, title, children, icon, icons }) {
    return (
        <div className="accordion-item border-0">
            <h2 className="accordion-header">
                <button
                    className="btn border-0 dropdown-toggle d-flex gap-2 align-items-center ps-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#${id}`}
                    aria-expanded="false"
                    aria-controls={id}
                >
<>
    {icons && (
        <div className="icons-container">
            {icons}
        </div>
    )}
    {!icons && icon && <img className="w-20 me-2" src={icon} alt={title} />}
    {title}
</>

                </button>
            </h2>
            <div id={id} className="accordion-collapse collapse">
                <div className="accordion-body">{children}</div>
            </div>
        </div>
    );
}

export default AccordionItem;
