// resources/js/app.js
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import WebSocketComponent from "./components/soket/WebSocketComponent.jsx";
import PostField from "./components/PostField/index.jsx";
import InfiniteScrollComponent from "./components/Stena/InfiniteScrollComponent.jsx";
import UserRandom from "./components/UserRandom.jsx";
import ShopContainer from "./components/Trash/Shop.jsx";
import Leaderboard from "./components/Leaderboard.jsx";
import SearchComponent from "./components/SearchComponent.jsx";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchCurrentUserId,
    fetchUsers,
    setOnlineUsers,
} from "./redux/usersSlice.js";
import { Provider } from "react-redux";
import store from "./redux/store";
import { fetchMoreData } from "./redux/stenasSlice.js";
import MeetingRoomBooking from "./components/Trash/MeetingRoomBooking.jsx";
import TransferCurrency from "./components/Trash/TransferCurrency.jsx";
import StenaPlaceholder from "./components/Stena/StenaPlaceholder.jsx";
import UserBlocks from "./components/UserBlocks.jsx";
import { fetchExtraTypes } from "./redux/extraTypesSlice.js";
import ReusableModal from "./components/ReusableModal.jsx";
import LikeHandlerComponent from "./components/Trash/LikeHandlerComponent.jsx";
import DigestList from "./components/Trash/DigestList.jsx";
import ChatContainer from "./components/Trash/ChatContainer.jsx";
import SettingsPopover from "./components/SettingsPopover.jsx";
import * as bootstrap from "bootstrap";
import Notifications from "./components/Notifications";
import MBTI from "./components/MBTI.jsx";
import { renderToStaticMarkup, renderToString } from "react-dom/server";
import SubscribePopover from "./components/SubscribePopover.jsx";
import SubscribeButton from "./components/SubscribeButton.jsx";
import TagsComponent from "./components/TagsComponent.jsx";
import { a } from "react-spring";
import BadgesListInModal from "./components/PostField/BadgesListInModal.jsx";
import { ca, id, ro } from "date-fns/locale";
import MedalsListInModal from "./components/PostField/MedalsListInModal.jsx";
import ShopModal from "./components/PostField/ShopModal/index.jsx";
import axios from "axios";
import ThanksInModalComponent from "./components/ThanksInModalComponent.jsx";
import ShopList from "./components/PostField/ShopList.jsx";
import ShopWrapper from "./components/ShopWrapper.jsx";

const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]'
);
const tooltipList = [...tooltipTriggerList].map(
    (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
);

const UserBlocksElement = document.getElementById("UserBlocks");
if (UserBlocksElement) {
    createRoot(UserBlocksElement).render(<UserBlocks />);
}

const TransferCurrencyElement = document.getElementById("TransferCurrency");
if (TransferCurrencyElement) {
    const recipientId = TransferCurrencyElement.getAttribute("data-user-id");
    createRoot(TransferCurrencyElement).render(
        <TransferCurrency recipientId={recipientId} />
    );
}

const MeetingRoomBookingElement = document.getElementById("MeetingRoomBooking");
if (MeetingRoomBookingElement) {
    createRoot(MeetingRoomBookingElement).render(<MeetingRoomBooking />);
}

const SearchComponentElementDesktop = document.getElementById(
    "SearchComponentDesktop"
);
if (SearchComponentElementDesktop) {
    createRoot(SearchComponentElementDesktop).render(
        <Provider store={store}>
            <SearchComponent />
        </Provider>
    );
}

const SearchComponentElementMobile = document.getElementById(
    "SearchComponentMobile"
);
if (SearchComponentElementMobile) {
    createRoot(SearchComponentElementMobile).render(
        <Provider store={store}>
            <SearchComponent />
        </Provider>
    );
}

const shopContainerElement = document.getElementById("shop-container");
if (shopContainerElement) {
    createRoot(shopContainerElement).render(<ShopContainer />);
}

const LeaderboardContainerElement = document.getElementById(
    "Leaderboard-container"
);
if (LeaderboardContainerElement) {
    createRoot(LeaderboardContainerElement).render(<Leaderboard />);
}

const chatContainerElement = document.getElementById("chat-container");
if (chatContainerElement) {
    createRoot(chatContainerElement).render(<ChatContainer />);
}

if (document.getElementById("digests-root")) {
    ReactDOM.render(<DigestList />, document.getElementById("digests-root"));
}

if (document.getElementById("UserRandom")) {
    const root = createRoot(document.getElementById("UserRandom"));
    root.render(<UserRandom />);
}

if (document.getElementById("LikeHandlerComponent")) {
    const root = createRoot(document.getElementById("LikeHandlerComponent"));
    root.render(<LikeHandlerComponent />);
}

if (document.getElementById("soket")) {
    const root = createRoot(document.getElementById("soket"));

    root.render(
        <Provider store={store}>
            <WebSocketComponent />
        </Provider>
    );
}

if (document.getElementById("post_field")) {
    createRoot(document.getElementById("post_field")).render(
        <Provider store={store}>
            <PostField />
        </Provider>
    );
}

//         // Обработка клика по имени пользователя внутри окна ввода комментария
document.addEventListener("click", (e) => {
    if (e.target.classList?.contains("reply-click")) {
        e.preventDefault();
        // Получаем значение атрибута href
        const href = e.target.getAttribute("href");

        // Извлекаем айди из href
        const commentId = href.split("_")[1];

        // Находим элемент с соответствующим айди
        const commentElement = document.querySelector(
            `#comment_${commentId} .comment-body`
        );
        const likeElement = document.querySelector(
            `#comment_${commentId} .comment-body .like-button`
        );

        // Если элемент найден, добавляем ему класс .bg-disabled на 3 секунды
        if (commentElement) {
            commentElement.classList.add("bg-disabled");
            likeElement.classList.add("bg-disabled");

            setTimeout(() => {
                commentElement.classList.remove("bg-disabled");
                likeElement.classList.remove("bg-disabled");
            }, 3000); // Удаляем класс через 3 секунды
        }
    }
});

// Делегирование событий для элементов с data-srcset
document.addEventListener("click", function (event) {
    // console.log("target", event.target);

    if (event.target && event.target.dataset?.srcset) {
        const element = event.target;
        const srcset = element.dataset.srcset;
        const altText = element.alt;
        const imgElement = document.createElement("img");
        imgElement.src = srcset;
        imgElement.alt = altText;
        if (event.target.closest(".gallery")) {
            const images = event.target
                .closest(".gallery")
                .querySelectorAll("img");
            if (images.length > 1) {
                showModal(imgElement, images);
            } else {
                showModal(imgElement);
            }
        } else {
            showModal(imgElement);
        }
    } else if (event.target && event.target.dataset?.mbti) {
        const element = event.target;
        const mbti = element.dataset.mbti;
        if (mbti) {
            showModal(<MBTI type={mbti} />);
        }
    } else if (
        (event.target && event.target.dataset?.userAction) ||
        event.target.closest("[data-user-action]")
    ) {
        const target = event.target.closest("[data-user-action]");
        if (target) {
            const action = target.dataset.userAction;
            const userId = target.dataset.userId;
            const userName = target.dataset.userName;
            console.log("action", action);

            if (!root) {
                root = createRoot(document.getElementById("modalInput"));
            } else {
                root.unmount();
                root = createRoot(document.getElementById("modalInput"));
            }

            let content, title;
            switch (action) {
                case "thanks":
                    content = (
                        <ThanksInModalComponent
                            onBtnClick={handleActionBtnClick}
                            userId={userId}
                            action={action}
                            onClose={() => root.unmount()}
                        />
                    );
                    title = (
                        <span>
                            {`Благодарность для пользователя `}
                            <span className="text-primary">{userName}</span>
                        </span>
                    );
                    break;
                case "reward":
                    content = (
                        <MedalsListInModal
                            isSecondStepRequired={true}
                            onBtnClick={handleActionBtnClick}
                            userId={userId}
                            action={action}
                        />
                    );
                    title = (
                        <span>
                            {`Выберите медаль для пользователя `}
                            <span className="text-primary">{userName}</span>
                        </span>
                    );
                    break;
                case "badge":
                    content = (
                        <BadgesListInModal
                            isSecondStepRequired={true}
                            onBtnClick={handleActionBtnClick}
                            userId={userId}
                            action={action}
                        />
                    );
                    title = (
                        <span>
                            {`Выберите бейдж для пользователя `}
                            <span className="text-primary">{userName}</span>
                        </span>
                    );
                    break;
                default:
                    content = null;
                    break;
            }
            root.render(
                <Provider store={store}>
                    {action !== "gift" ? (
                        <ReusableModal
                            content={content}
                            show={true}
                            onClose={() => {}}
                            header={true}
                            footer={false}
                            large={true}
                            title={title}
                        />
                    ) : (
                        <ShopModal
                            show={true}
                            selectedUser={{ id: userId, name: userName }}
                            handleGiftConfirmationClick={handleActionBtnClick}
                            onClose={() => root.unmount()}
                            action={action}
                            isCommentForUserRequired={true}
                        />
                    )}
                </Provider>
            );
        }
    }
});

function handleActionBtnClick({ action, id, comment, userId, value = 0 }) {
    console.log("action", action, "id", id, "comment", comment, "value", value);

    const payload = {
        content: comment,
        action,
        userId,
    };

    // Условие для передачи соответствующего массива
    if (action === "reward" || action === "badge") {
        payload.awardsForUsers = [id];
    } else if (action === "gift") {
        payload.giftsForUsers = [id];
    } else if (action === "thanks") {
        payload.redicsForUsers = {
            [id]: value,
        };
    }

    sendPost(payload);
}

const sendPost = ({
    content,
    action,
    userId,
    redicsForUsers,
    awardsForUsers = [],
    giftsForUsers = [],
}) => {
    const formData = new FormData();
    const token = document.querySelector('meta[name="csrf-token"]').content;
    formData.append("user_id_wall_post", userId);
    formData.append("body", content);
    formData.append("_token", token);
    if (action === "") {
        formData.append("type", "");
    } else if (action === "thanks") {
        formData.append("html", "true");
        formData.append("type", "gratitude");
        formData.append("redik", JSON.stringify(redicsForUsers));
    } else if (action === "reward") {
        formData.append("type", "medal_awarded");
    } else if (action === "badge") {
        formData.append("type", "badges");
    } else if (action === "gift") {
        formData.append("type", "shop");
    }
    const formatedItemsForUsers = {};
    if (awardsForUsers.length) {
        awardsForUsers.map((medal) => {
            return (formatedItemsForUsers[userId] = medal);
        });
    } else if (giftsForUsers.length) {
        giftsForUsers.map((gift) => {
            return (formatedItemsForUsers[userId] = gift);
        });
    }
    if (Object.keys(formatedItemsForUsers).length) {
        formData.append("redik", JSON.stringify(formatedItemsForUsers));
    }
    axios
        .post("/stenas/storeTestJS", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then((response) => {
            // Обработайте успешный ответ от сервера, если необходимо
            console.log("Сообщение успешно отправлено");
            if (action === "gift") {
                // alert("На время тестирования мяукойны не списываются!");
            }
            root.unmount();
        })
        .catch((error) => {
            // Обработайте ошибку, если отправка не удалась
            console.error("Ошибка при отправке сообщения:", error);
        });
};

let root;

// Функция для открытия модального окна
function showModal(el, images = [], title = false) {
    if (!root) {
        root = createRoot(document.getElementById("modalInput"));
    } else {
        root.unmount();
        root = createRoot(document.getElementById("modalInput"));
    }

    const content = React.isValidElement(el) ? (
        <div dangerouslySetInnerHTML={{ __html: renderToString(el) }} />
    ) : (
        <div>{el.innerHTML}</div>
    );

    root.render(
        <ReusableModal
            content={
                el.tagName === "IMG" ? (
                    <img
                        className="w-100"
                        src={el.src}
                        alt={el.alt}
                        style={{
                            maxHeight: "90vh",
                            maxWidth: "fit-content",
                        }}
                    />
                ) : (
                    content
                )
            }
            show={true}
            onClose={() => {}}
            header={title ? true : false}
            footer={false}
            large={true}
            images={images}
            title={title}
        />
    );
}

document.addEventListener("click", function (event) {
    if (event.target && event.target.closest("#stena_settings")) {
        root = createRoot(document.getElementById("popoverInput"));
        root.render(
            <Provider store={store}>
                <SettingsPopover target={event.target} show={true} />
            </Provider>
        );
    }
    if (event.target && event.target.closest(".sk-notification-icon")) {
        root = createRoot(document.getElementById("popoverInput"));
        root.render(
            <Provider store={store}>
                <Notifications
                    target={event.target}
                    show={true}
                    id={Notifications.sender_id}
                />
            </Provider>
        );
    }
});

document.addEventListener("click", function (event) {
    //костыль: устраняем странный косяк с работой категории suggested в смайлах
    const emojis = document.querySelector(".epr-body");
    if (emojis) {
        const smiles = emojis.querySelectorAll(
            `[data-name="smileys_people"]`
        )[0];
        if (smiles) {
            const smilesClasses = smiles.className;
            const suggestedInLocalStorage = JSON.parse(
                localStorage.getItem("epr_suggested")
            );
            const suggestedSmiles = emojis.querySelectorAll(
                `[data-name="suggested"]`
            )[0];
            if (suggestedSmiles && suggestedInLocalStorage) {
                suggestedSmiles.className = smilesClasses;
            }
        }
    }
});

const subscribeBtn = document.getElementById("subscribe-btn");
if (subscribeBtn) {
    root = createRoot(subscribeBtn);
    root.render(
        <Provider store={store}>
            <SubscribeButton />
        </Provider>
    );
}

const tagRoots = document.querySelectorAll(".tag-root");

tagRoots.forEach((tagRoot) => {
    // Получаем строку из data-tags и преобразуем её в массив
    const tags = tagRoot.dataset.tags ? tagRoot.dataset.tags.split(",") : [];

    const root = createRoot(tagRoot);
    root.render(<TagsComponent tagsFromProps={tags} inputName="tags" />);
});

const meowMarketRoot = document.querySelector(".meow-market");
if (meowMarketRoot) {
    let activeCategory;

    // Инициализация React-приложения
    const root = createRoot(meowMarketRoot);

    // Первая отрисовка ShopWrapper без activeCategory
    root.render(
        <Provider store={store}>
            <ShopWrapper />
        </Provider>
    );

    const categories = document.querySelectorAll("[data-category-id]");
    document.addEventListener("click", (e) => {
        if (e.target.closest("[data-category-id]")) {
            activeCategory = e.target.dataset.categoryId;
            root.render(
                <Provider store={store}>
                    <ShopWrapper activeCategory={activeCategory} />
                </Provider>
            );
        }
    });
}

const App = () => {
    const [hasMore, setHasMore] = useState(true); // Есть ли еще данные для загрузки
    const [showStenaComponent, setShowStenaComponent] = useState(false); // Флаг, чтобы отслеживать открытие
    const dispatch = useDispatch();
    // const { users } = useSelector((state) => state.users);
    // const { currentUser } = useSelector((state) => state.users);
    const { stenas } = useSelector((state) => state.stenas);
    const pageInUseSelector = useSelector((state) => state.stenas.page);
    const [page, setPage] = useState(pageInUseSelector || 0);
    const [fetchFlag, setFetchFlag] = useState(true);
    const [fetchFlagURL, setFetchFlagfetchFlagURL] = useState(true);
    const [Loadings, setLoadings] = useState(true); // Добавим состояние для отслеживания завершения загрузки

    let isFetched = false;
    useEffect(() => {
        const fetchUsersData = async () => {
            if (!isFetched && fetchFlagURL) {
                const stenaDiv = document.getElementById("stena");
                const shopDiv = document.getElementById("shop");
                if (stenaDiv || shopDiv) {
                    isFetched = true;
                    await dispatch(fetchUsers(fetchFlagURL));
                    await dispatch(fetchCurrentUserId(fetchFlagURL));
                    await dispatch(fetchExtraTypes(fetchFlagURL));
                    setFetchFlagfetchFlagURL(false);
                }
            }
        };
        fetchUsersData();
    }, [fetchFlagURL, dispatch]);

    useEffect(() => {
        if (stenas.length) {
            setShowStenaComponent(true);
        }
    }, [stenas]);

    useEffect(() => {
        if (fetchFlag) {
            const stenaDiv = document.getElementById("stena");
            const shopDiv = document.getElementById("shop");
            if (stenaDiv || shopDiv) {
                fetchData();
                setFetchFlag(false);
            }
        }
        setFetchFlagfetchFlagURL(false);
    }, [dispatch, fetchFlag]);

    const loadedPages = [];
    const fetchData = async () => {
        if (loadedPages.includes(page)) {
            console.log(`Страница ${page} уже была загружена`);
            return; // Прерываем выполнение, если страница уже загружена
        }

        try {
            loadedPages.push(page); // Добавляем страницу в загруженные
            const res = await dispatch(fetchMoreData(page + 1, hasMore));

            const { data, hasMore: newHasMore, isFirstPageEmpty } = res.payload;

            if (isFirstPageEmpty) {
                const stenaDiv = document.getElementById("stena");
                if (stenaDiv && stenaDiv._reactRootContainer) {
                    stenaDiv._reactRootContainer.render(
                        <div className="text-center mt-4">
                            <p className="fs-4 text-primary">
                                Здесь пока пусто
                            </p>
                            <p className="text-muted">
                                Записи ещё не добавлены. Возможно, вы станете
                                первым!
                            </p>
                        </div>
                    );
                }
                return; // Прекращаем выполнение, чтобы не продолжать логику
            }

            if (data.length === 0) {
                setHasMore(false); // Прекращаем загрузку, если данных нет
                setLoadings(false);
            } else {
                setHasMore(newHasMore); // Обновляем состояние hasMore с результатом из API
                setLoadings(true);
            }

            setPage((prevPage) => prevPage + 1); // Если данные есть, увеличиваем страницу
        } catch (error) {
            console.error("Ошибка загрузки данных:", error);
        }
    };

    useEffect(() => {
        const fetchOnlineUsers = async () => {
            try {
                const { data } = await axios.get("/getOnlineUsers");
                // console.log("Online Users:", data);
                if (data.length) {
                    dispatch(setOnlineUsers(data));
                }
            } catch (error) {
                console.error("Ошибка запроса онлайн пользователей:", error);
            }
        };

        const intervalId = setInterval(fetchOnlineUsers, 2 * 60 * 1000);

        // Выполнить запрос сразу при монтировании компонента
        fetchOnlineUsers();

        // Очистка интервала при размонтировании компонента
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const stenaDiv = document.getElementById("stena");
        // Проверяем, существует ли уже корень для stenaDiv
        if (stenaDiv && !stenaDiv._reactRootContainer) {
            const stenaRoot = createRoot(stenaDiv);
            // Сохраняем ссылку на корень в свойстве DOM элемента для последующего доступа
            stenaDiv._reactRootContainer = stenaRoot;
        }
        if (stenaDiv) {
            const filter = stenaDiv.getAttribute("data-filter")
                ? JSON.parse(stenaDiv.getAttribute("data-filter"))
                : null;

            if (showStenaComponent) {
                // Создаем корень React и рендерим компонент внутри div#stena
                stenaDiv._reactRootContainer.render(
                    <>
                        {stenas.length ? (
                            <>
                                <Provider store={store}>
                                    <InfiniteScrollComponent
                                        hasMore={hasMore}
                                        Loadings={Loadings} // Передаем флаг загрузки
                                        fetchMoreData={() => setFetchFlag(true)}
                                        filter={filter}
                                    />
                                </Provider>
                            </>
                        ) : (
                            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
                                <StenaPlaceholder key={i} />
                            ))
                        )}
                    </>
                );
            } else {
                stenaDiv._reactRootContainer.render(
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
                        <StenaPlaceholder key={i} />
                    ))
                );
            }
        }
    }, [showStenaComponent, Loadings]);
};

export default App;

const appContainer = document.getElementById("app");
if (appContainer) {
    const appRoot = createRoot(appContainer);
    appRoot.render(
        <React.StrictMode>
            <Provider store={store}>
                <App />
            </Provider>
        </React.StrictMode>
    );
}
