import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import InfiniteScroll from 'react-infinite-scroll-component';
import { format, isSameDay } from 'date-fns';

const MessagesList = ({ dialog, dialogId }) => {
    const [messages, setMessages] = useState([]);
    const [dialogDetails, setDialogDetails] = useState(null); // Для хранения информации о диалоге
    const [newMessage, setNewMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const [authUserId, setAuthUserId] = useState("");
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const messagesEndRef = useRef(null);
    const fileInputRef = useRef(null);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [viewedMessageIds, setViewedMessageIds] = useState([]);

    useEffect(() => {
        setMessages([]);
        setPage(1);
        fetchDialogDetails(); // Загружаем детали диалога
    }, [dialog]);

    useEffect(() => {
        fetchMessages();
        setViewedMessageIds([]); // Очистить массив просмотренных сообщений
    }, [dialog, page]);

    const fetchDialogDetails = async () => {
        try {
            const response = await axios.get(`/chat/dialogs/${dialog.id}`);
            setDialogDetails(response.data.dialog); // Сохраняем информацию о диалоге
        } catch (error) {
            console.error("Ошибка при загрузке данных о диалоге:", error);
        }
    };

    const fetchMessages = async () => {
        try {
            setLoading(true);
            setIsLoadingMore(true);
            const response = await axios.get(
                `/chat/dialogs/${dialog.id}/messages?page=${page}&viewed=${viewedMessageIds.join(',')}`
            );
            setMessages(prevMessages => [...response.data.messages.data.reverse(), ...prevMessages]);
            setAuthUserId(response.data.authUserId);
            setHasMore(response.data.messages.last_page > page);
            setLoading(false);
            setIsLoadingMore(false);

            setViewedMessageIds(prevIds => [
                ...prevIds,
                ...response.data.messages.data
                    .filter(message => message.sender_id !== authUserId)
                    .map(message => message.id),
            ]);
        } catch (error) {
            console.error("Ошибка при загрузке сообщений:", error);
            setLoading(false);
            setIsLoadingMore(false);
        }
    };

    const handleSendMessage = async (event) => {
        event.preventDefault();
        if (!newMessage.trim()) return;

        try {
            const response = await axios.post("/chat/messages", {
                dialog_id: dialog.id,
                body: newMessage,
            });
            setMessages(messages => {
                const isDuplicate = messages.some(message => message.id === response.data.messageData.id);
                if (!isDuplicate) {
                    return [...messages, response.data.messageData];
                }
                return messages;
            });
            setNewMessage("");
            scrollToBottom();
        } catch (error) {
            console.error("Ошибка при отправке сообщения:", error);
        }
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        if (page === 1) {
            scrollToBottom();
        }
    }, [messages]);

    return (
        <div
            className="messages-wrapper d-flex flex-column vh-100"
            style={{
                display: 'flex',
                flexDirection: 'column',

            }}
        >
            {/* Информация об участниках группы */}

            <div
    className="participants-info p-2"
    style={{
        borderBottom: "1px solid #ddd",
        background: "#f9f9f9",
    }}
>
    <h6>
        Участники (<span>{dialogDetails?.users?.length || 0}</span>)
    </h6>

    {/* Контейнер с участниками */}
    <div
        className="m-2 collapse"
        id="participantsCollapse"
        style={{
            display: "flex",
            flexWrap: "wrap",

        }}
    >
        {(dialogDetails?.users || []).map((user) => (
            <div
                key={user.id}
                className="text-center position-relative me-3"
                style={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                }}
            >
                {/* Выпадающее меню */}
                <div className="dropdown">
                    <button
                        className="btn p-0"
                        type="button"
                        id={`userDropdown${user.id}`}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                        }}
                    >
                        {user?.avatar?.medium_url ? (
                            <img
                                src={user.avatar.medium_url}
                                alt={user.name}
                                title={user.name}
                                className="rounded-circle border"
                                style={{
                                    width: "48px",
                                    height: "48px",
                                    objectFit: "cover",
                                }}
                            />
                        ) : (
                            <div
                                className="rounded-circle bg-primary text-white d-flex align-items-center justify-content-center"
                                title={user.name}
                                style={{
                                    width: "48px",
                                    height: "48px",
                                    fontSize: "16px",
                                }}
                            >
                                {user.short_name}
                            </div>
                        )}
                    </button>

                    {/* Меню */}
                    <ul
                        className="dropdown-menu"
                        aria-labelledby={`userDropdown${user.id}`}
                        style={{
                            zIndex: 1050,
                        }}
                    >
                        <li>
                            <a
                                className="dropdown-item"
                                href={`/user/${user.id}`}
                            >
                                Профиль
                            </a>
                        </li>
                        <li>
                            <button
                                className="dropdown-item text-danger"
                                onClick={() =>
                                    console.log(
                                        `Удалить пользователя: ${user.id}`
                                    )
                                }
                            >
                                Удалить
                            </button>
                        </li>
                    </ul>
                </div>

                {/* Имя пользователя */}
                <span
                    style={{
                        fontSize: "12px",
                        marginTop: "5px",
                        display: "block",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "80px",
                    }}
                    title={user.name}
                >
                    {user.name}
                </span>
            </div>
        ))}
    </div>


</div>

            {/* Контейнер для сообщений */}
            <div
                id="messages-scroll-container"
                className="flex-grow-1 overflow-auto"
                style={{
                    flex: 1,
                    flexDirection: 'column-reverse',
                }}
            >
                <InfiniteScroll
                    dataLength={messages.length}
                    next={() => setPage(page + 1)}
                    hasMore={hasMore}
                    loader={isLoadingMore && <div className="alert alert-primary text-center">Загрузка...</div>}
                    inverse={true}
                    scrollableTarget="messages-scroll-container"
                >
                    {messages.reduce((acc, message, index, array) => {
                        const messageDate = new Date(message.created_at);
                        const lastMessageDate =
                            index > 0 ? new Date(array[index - 1].created_at) : null;
                        const isCurrentUser = message.sender_id === authUserId;

                        if (
                            index === 0 ||
                            (lastMessageDate && !isSameDay(messageDate, lastMessageDate))
                        ) {
                            acc.push(
                                <div
                                    key={message.id + "-date"}
                                    className="badge bg-secondary text-white p-3 m-3 push-whatsapp-date"
                                >
                                    {format(messageDate, "dd.MM.yyyy") ===
                                    format(new Date(), "dd.MM.yyyy")
                                        ? "Сегодня"
                                        : format(messageDate, "dd.MM.yyyy")}
                                </div>
                            );
                        }

                        acc.push(
                            <div
                                key={message.id}
                                className={`whatsapp-message-container ${
                                    isCurrentUser ? "whatsapp-right" : "whatsapp-left"
                                }`}
                            >
                                {message.sender_id !== authUserId && (
                                    <img
                                        src={
                                            message.sender.avatar
                                                ? message.sender.avatar.thumbnail_url
                                                : "/img/sk-logo.svg"
                                        }
                                        alt={`${message.sender.name}'s Avatar`}
                                        className={`whatsapp-avatar ${
                                            isCurrentUser ? "whatsapp-avatar-right" : ""
                                        }`}
                                    />
                                )}
                                <div
                                    className={`whatsapp-message-text ${
                                        isCurrentUser ? "whatsapp-right-text" : ""
                                    }`}
                                >
                                    {isCurrentUser ? null : (
                                        <div className="mb-1">
                                            <a
                                                href={`/user/${message.sender.id}`}
                                                className="user-link d-block"
                                            >
                                                {message.sender.name}
                                            </a>
                                        </div>
                                    )}
                                    {message.body}{" "}
                                    <span className="whatsapp-time  d-flex justify-content-end">
                                        {format(messageDate, "HH:mm")}
                                    </span>
                                </div>
                            </div>
                        );
                        return acc;
                    }, [])}
                    <div ref={messagesEndRef} />
                </InfiniteScroll>
            </div>

            {/* Форма ввода сообщения */}
            <form
                onSubmit={handleSendMessage}
                encType="multipart/form-data"
                style={{
                    borderTop: "1px solid #ddd",
                    padding: "10px",
                    background: "#fff",
                }}
            >
                <div className="input-group">
                    <textarea
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        placeholder="Написать сообщение..."
                        className="form-control"
                        style={{ resize: "none" }}
                    />
                    <input
                        type="file"
                        onChange={handleFileChange}
                        accept="image/*, video/*, audio/*"
                        style={{ display: "none" }}
                        name="file"
                        ref={fileInputRef}
                    />
                    <div className="input-group-append">
                        <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => fileInputRef.current.click()}
                        >
                            📎
                        </button>
                        <button type="submit" className="btn btn-primary">✉️</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default MessagesList;
