import React, { useEffect, useState } from "react";
import SearchSVG from "../../Icons/SearchSVG";
import LikeSVG from "../../Icons/LikeSVG";
import ShopList from "../ShopList";

const FirstStage = ({
    Shop,
    inputRef,
    activeInputState,
    setActiveInputState,
    handleItemSelect,
    presentCostOfGifts,
}) => {
    const [inputValue, setInputValue] = useState("");
    const [filtredItems, setFiltredItems] = useState(Shop || []);
    const [showFavorites, setShowFavorites] = useState(false);
    const sortinArray = [
        {
            id: 0,
            type: "id",
            title: "По умолчанию",
            order: "asc",
        },
        {
            id: 1,
            type: "summ",
            title: "Сначала дорогие",
            order: "desc",
        },
        {
            id: 2,
            type: "summ",
            title: "Сначала недорогие",
            order: "asc",
        },
    ];
    // console.log("inputRef", inputRef);

    const [sorting, setSorting] = useState(sortinArray[0]);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        if (e.target.value !== "") {
            setFiltredItems((prevState) =>
                prevState?.filter((item) =>
                    item?.name?.toLowerCase()?.includes(e.target.value)
                )
            );
        } else {
            setFiltredItems(Shop);
        }
    };

    const handleInputClick = () => {
        setActiveInputState(true);
    };

    const toggleFavorites = () => {
        setShowFavorites(!showFavorites);
    };

    useEffect(() => {
        if (showFavorites) {
            const favArray = JSON.parse(localStorage.getItem("favorites"));
            const newFiltredItems = Shop.filter((item) => {
                return favArray.includes(item.id);
            });
            setFiltredItems(newFiltredItems);
        } else {
            setFiltredItems(Shop);
        }
    }, [showFavorites]);

    useEffect(() => {
        if (filtredItems.length) {
            setFiltredItems((prevState) => {
                const sortedItems = [...prevState]; // Создаем копию массива
                if (sorting.type === "id") {
                    sortedItems.sort((a, b) => a.id - b.id);
                } else if (sorting.type === "summ") {
                    if (sorting.order === "asc") {
                        sortedItems.sort((a, b) => a.summ - b.summ);
                    } else if (sorting.order === "desc") {
                        sortedItems.sort((a, b) => b.summ - a.summ);
                    }
                }
                return sortedItems;
            });
        }
    }, [sorting]);

    return (
        <div>
            <div className="d-md-block d-none">
                <div className="d-flex flex-column gap-4">
                    <div className="d-flex gap-3 align-items-center">
                        <div className="input-group align-items-center">
                            <span className="z-999">
                                <button
                                    className="btn border-0 me-n5"
                                    type="button"
                                >
                                    <SearchSVG active={activeInputState} />
                                </button>
                            </span>
                            <input
                                type="text"
                                className="form-control ps-5 rounded bg-gray-2 border-gray-2"
                                placeholder="Поиск по МяуМаркету"
                                aria-label="Поиск по МяуМаркету"
                                value={inputValue}
                                ref={activeInputState ? inputRef : null}
                                onClick={handleInputClick}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <button
                                type="button"
                                className="btn bg-gray-2 border-0"
                                onClick={toggleFavorites}
                            >
                                <LikeSVG isLiked={showFavorites} />
                            </button>
                        </div>

                        {sorting.title ? (
                            <div className="dropdown">
                                <button
                                    className="btn text-muted text-nowrap p-0 border-0"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Сортировка:{" "}
                                    <span className="text-lowercase">
                                        {sorting.title}
                                    </span>
                                </button>

                                {sortinArray.length ? (
                                    <div className="dropdown-menu pe-5">
                                        {sortinArray
                                            .filter(
                                                (item) => item.id !== sorting.id
                                            )
                                            .map((item) => (
                                                <li key={item.id}>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                        key={item.id}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setSorting(item);
                                                        }}
                                                    >
                                                        {item.title}
                                                    </a>
                                                </li>
                                            ))}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <ShopList
                        shopList={filtredItems}
                        handleItemSelect={handleItemSelect}
                    />
                </div>
            </div>
            <div className=" d-md-none d-block">
                <div className="d-flex flex-column gap-4">
                    <div className="d-flex align-items-center">
                        <div className="input-group align-items-center">
                            <span className="z-999">
                                <button
                                    className="btn border-0 me-n5"
                                    type="button"
                                >
                                    <SearchSVG active={activeInputState} />
                                </button>
                            </span>
                            <input
                                type="text"
                                className="form-control ps-5 rounded bg-gray-2 border-gray-2"
                                placeholder="Поиск по МяуМаркету"
                                aria-label="Поиск по МяуМаркету"
                                value={inputValue}
                                ref={activeInputState ? inputRef : null}
                                onClick={handleInputClick}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <button
                                type="button"
                                className="btn bg-gray-2 border-0"
                                onClick={toggleFavorites}
                            >
                                <LikeSVG isLiked={showFavorites} />
                            </button>
                        </div>
                    </div>

                    {sorting.title && (
                        <div className="dropdown">
                            <button
                                className="btn text-muted text-nowrap p-0 border-0"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Сортировка:{" "}
                                <span className="text-lowercase">
                                    {sorting.title}
                                </span>
                            </button>

                            {sortinArray.length && (
                                <div className="dropdown-menu pe-5">
                                    {sortinArray
                                        .filter(
                                            (item) => item.id !== sorting.id
                                        )
                                        .map((item) => (
                                            <li key={item.id}>
                                                <a
                                                    className="dropdown-item"
                                                    href="#"
                                                    key={item.id}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setSorting(item);
                                                    }}
                                                >
                                                    {item.title}
                                                </a>
                                            </li>
                                        ))}
                                </div>
                            )}
                        </div>
                    )}
                    <ShopList
                        shopList={filtredItems}
                        handleItemSelect={handleItemSelect}
                    />
                </div>
            </div>
            <ShopList
                shopList={filtredItems}
                handleItemSelect={handleItemSelect}
                presentCostOfGifts={presentCostOfGifts}
            />
        </div>
    );
};

export default FirstStage;
