import LikeSVG from "../Icons/LikeSVG";
import MeowSVG from "../Icons/MeowSVG";
import ShopBasketSVG from "../Icons/ShopBasketSVG";

const ShopCard = ({ item, active, favorite, onBtnClick }) => {
    const { name, image, body, summ, id } = item;
    const handleFavoriteItemClick = () => {
        const favArray = JSON.parse(localStorage.getItem("favorites")) || [];
        if (favArray.includes(item.id)) {
            const index = favArray.indexOf(item.id);
            favArray.splice(index, 1);
        } else {
            favArray.push(item.id);
        }
        localStorage.setItem("favorites", JSON.stringify(favArray));
    };
    return (
        <div className="col">
            <div className="card h-100 border-0 position-relative gap-1 ">
                <img
                    src={image}
                    className="card-img-top border"
                    alt={name}
                ></img>
                {/* <button
                    className="btn bg-gray-2 position-absolute top-0 end-0 m-2 px-2 py-1 border-0 "
                    onClick={handleFavoriteItemClick}
                >
                    <LikeSVG isLiked={favorite} />
                </button> */}
                <div className="card-body p-0">
                    <h5 className={active ? "text-primary" : "text-muted"}>
                        {summ}
                        <MeowSVG />
                    </h5>
                    <span className={active ? "text-secondary" : "text-muted"}>
                        {name}
                    </span>
                </div>
                <div className="card-footer bg-transparent border-0 p-0">
                    <button
                        className={
                            "btn btn-sm w-100 px-4 " +
                            (active ? "btn-primary" : "bg-gray-4 border-gray-4")
                        }
                        disabled={!active}
                        onClick={() => onBtnClick(item)}
                    >
                        <ShopBasketSVG />
                        <span
                            className={"ms-2 " + (active ? "" : "text-white")}
                        >
                            Выбрать
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ShopCard;
