import React from "react";
import { generateColorFromId } from "../../functions";

function UserLogo({ user, size = "medium" }) {
    // console.log("size", size);

    let imageSizeClass;
    switch (size) {
        case "small":
            imageSizeClass = "w-32 h-32";
            break;
        case "medium":
            imageSizeClass = "w-48 h-48";
            break;
        case "extra-small": // новый размер
            imageSizeClass = "w-20 h-20"; // ширина и высота 20px
            break;
        default:
            imageSizeClass = "w-48 h-48";
            break;
    }

    const lastActiveTime = new Date(user?.last_active_at);
    const twoMinutesAgo = new Date(Date.now() - 2 * 60 * 1000);

    return (
        <a
            href={`/user/${user.id}`}
            className="link-underline link-underline-opacity-0 position-relative"
        >
            {user.avatar ? (
                <img
                    className={
                        "rounded-circle object-fit-cover " + imageSizeClass
                    }
                    src={
                        user.avatar.thumbnail_url[0] === "/"
                            ? user.avatar.thumbnail_url
                            : `/${user.avatar.thumbnail_url}`
                    }
                    alt={user.short_name}
                />
            ) : (
                <div
                    className={
                        "rounded-circle object-fit-cover text-white d-flex align-items-center justify-content-center " +
                        imageSizeClass
                    }
                    style={{
                        backgroundColor: generateColorFromId(user.id),
                    }}
                >
                    {user.short_name}
                </div>
            )}
            {user.last_active_at && lastActiveTime > twoMinutesAgo && (
                <span
                    className={
                        "position-absolute end-0 bottom-0 status-dot status-dot-" +
                        size
                    }
                ></span>
            )}
        </a>
    );
}

export default UserLogo;
