import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import SubscribePopover from "./SubscribePopover";

function SubscribeButton() {
    const [isSubscribedByUser, setIsSubscribedByUser] = useState(false);
    const [showPopover, setShowPopover] = useState(false);
    const currentUser = useSelector((state) => state.users?.currentUser);
    const ref = useRef(null);
    const [usersWhoSubscribed, setUsersWhoSubscribed] = useState([]);


    const [isLoading, setIsLoading] = useState(true);

    const stenaDivs = document.getElementById("stena");
    const userIdFromBlade = stenaDivs ? stenaDivs.getAttribute("data-user") : null;

       useEffect(() => {
        // Извлекаем userId из data-user
        if (userIdFromBlade) {
            setIsLoading(true);
            axios
                .get(`/usersWhoLiked/user/${userIdFromBlade}`)
                .then((response) => {
                    const userIds = response.data.data.map((user) => user.id);
                    setUsersWhoSubscribed(userIds);
                })
                .catch((error) => {
                    console.error("Ошибка при получении данных о пользователях:", error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [userIdFromBlade]); // Убедитесь, что useEffect отслеживает изменение userIdFromBlade

    const handleSubscribeClick = () => {
        if (!userIdFromBlade) {
            console.error("Ошибка: не найден userId из data-user");
            return;
        }

        axios
            .get(`/like/user/${userIdFromBlade}`)
            .then((response) => {
                setIsSubscribedByUser(!isSubscribedByUser);
            })
            .catch((error) => {
                console.error("Ошибка при отправке запроса на сервер:", error);
            });

        if (isSubscribedByUser) {
            setUsersWhoSubscribed((prevState) =>
                prevState.filter((id) => id !== currentUser.id)
            );
        } else {
            setUsersWhoSubscribed((prevState) => [
                ...prevState,
                currentUser.id,
            ]);
        }
        setIsSubscribedByUser(!isSubscribedByUser);
    };

    function handleSubscribePopoverChange(value) {
        if (usersWhoSubscribed.length) {
            if (value && !showPopover) {
                setShowPopover(value);
            } else if (!value && showPopover) {
                setShowPopover(value);
            }
        }
    }

    return (
        <div
            className="d-flex bg-white"
            onMouseEnter={() => handleSubscribePopoverChange(true)}
            onMouseLeave={() => handleSubscribePopoverChange(false)}
        >
            <div
                className="rounded border-0 bg-gray-2 sk-hover-gray-3 px-2 py-1"
                role="button"
                onClick={handleSubscribeClick}
                ref={ref}
                data-user={userIdFromBlade} // Теперь используем userId из props
            >
                {isSubscribedByUser ? (
                    <small className="text-gray-6-bg">
                        Вы подписаны{" "}
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M3.33301 8.66699L5.99967 11.3337L12.6663 4.66699"
                                stroke="#8C8C8C"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </small>
                ) : (
                    <small className="text-primary">Подписаться +</small>
                )}
                <small className="text-gray-6-bg p-1">
                    {isLoading ? "..." : usersWhoSubscribed.length}
                </small>
            </div>
            {showPopover ? (
                <SubscribePopover
                    usersWhoSubscribed={usersWhoSubscribed}
                    target={ref.current}
                    handleSubscribePopoverChange={handleSubscribePopoverChange}
                />
            ) : (
                ""
            )}
        </div>
    );
}


export default SubscribeButton;
