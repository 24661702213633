import React, { useState, useEffect } from 'react';
import axios from 'axios';

const NewDialogForm = ({ onDialogCreated }) => {
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [dialogName, setDialogName] = useState('Новый диалог');
    const [filterDepartment, setFilterDepartment] = useState('');
    const [filterName, setFilterName] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [currentUserDepartment, setCurrentUserDepartment] = useState('');

    useEffect(() => {
        // Получение ID текущего пользователя
        axios.get('/get-current-user-id')
            .then(response => {
                setCurrentUserId(response.data.userId);
                setSelectedUsers([response.data.userId]); // Автоматически добавляем текущего пользователя
            })
            .catch(error => console.error('Ошибка при получении ID текущего пользователя:', error));

        // Загрузка всех пользователей
        axios.get('/users/json')
            .then(response => setUsers(response.data))
            .catch(error => console.error('Ошибка при загрузке пользователей:', error));
    }, []);

    useEffect(() => {
        // Устанавливаем отдел текущего пользователя, если данные уже загружены
        if (currentUserId && users.length > 0) {
            const currentUser = users.find(user => user.id === currentUserId);
            if (currentUser && currentUser.user_details?.department?.name) {
                setCurrentUserDepartment(currentUser.user_details.department.name);
            }
        }

        filterUsers(filterDepartment, filterName);
    }, [users, currentUserId, filterDepartment, filterName]);

    const filterUsers = (department, name) => {
        const filtered = users
            .filter(user => user.id !== currentUserId) // Исключаем текущего пользователя
            .filter(user => {
                const isSameDepartment = !department ||
                    (user.user_details?.department?.name || '').toLowerCase().includes(department.toLowerCase());
                const isNameMatch = !name || user.name.toLowerCase().includes(name.toLowerCase());
                return isSameDepartment && isNameMatch;
            })
            .sort(user =>
                user.user_details?.department?.name === currentUserDepartment ? -1 : 1 // Пользователи из того же отдела идут первыми
            );

        setFilteredUsers(filtered);
    };

    const handleUserSelection = (userId) => {
        setSelectedUsers(prevSelectedUsers =>
            prevSelectedUsers.includes(userId)
                ? prevSelectedUsers.filter(id => id !== userId) // Убираем из выбранных
                : [...prevSelectedUsers, userId] // Добавляем в выбранные
        );
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        axios.post('/chat/dialogs', {
            name: dialogName || 'Новый диалог',
            participants: selectedUsers
        })
        .then(response => {
            setDialogName('Новый диалог');
            setSelectedUsers([currentUserId]); // Сбрасываем выбор, оставляем только текущего пользователя
            onDialogCreated(response.data.dialog);
        })
        .catch(error => console.error('Ошибка при создании диалога:', error));
    };

    return (
        <div className="container mt-3 px-3 py-2 shadow-lg rounded" style={{ backgroundColor: '#f9f9f9' }}>
            <h4 className="text-center mb-4">Создание нового диалога</h4>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <input
                        type="text"
                        value={dialogName}
                        onChange={e => setDialogName(e.target.value)}
                        className="form-control"
                        placeholder="Название диалога"
                    />
                </div>
                <div className="d-flex gap-2 mb-3">
                    <input
                        type="text"
                        value={filterDepartment}
                        onChange={e => setFilterDepartment(e.target.value)}
                        className="form-control"
                        placeholder="Фильтр по отделу"
                    />
                    <input
                        type="text"
                        value={filterName}
                        onChange={e => setFilterName(e.target.value)}
                        className="form-control"
                        placeholder="Фильтр по имени"
                    />
                </div>
                <div className="overflow-auto" style={{ maxHeight: '300px', border: '1px solid #ddd', borderRadius: '5px', padding: '10px' }}>
                    {filteredUsers.map(user => (
                        <div
                            key={user.id}
                            className={`d-flex align-items-center mb-2 p-2 rounded ${selectedUsers.includes(user.id) ? 'bg-primary text-white' : 'bg-light'}`}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleUserSelection(user.id)}
                        >
                            <img
                                src={user.avatar ? user.avatar.thumbnail_url : '/img/sk-logo.svg'}
                                alt={`${user.name}'s Avatar`}
                                className="rounded-circle w-48 h-48 object-fit-cover"

                            />
                            <div>
                                <h6 className="mb-0">{user.name}</h6>
                                {user.user_details?.department?.name && (
                                    <small className={`text-muted ${selectedUsers.includes(user.id) ? 'text-white' : ''}`}>
                                        Отдел: {user.user_details.department.name}
                                    </small>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="mb-3 mt-3">
                    <h5>Выбранные пользователи:</h5>
                    {selectedUsers.map(userId => {
    const user = users.find(user => user.id === userId);
    if (!user) return null; // Пропустить, если пользователь не найден

    return (
        <div key={userId} className="d-flex align-items-center mb-2">
            <img
                src={user.avatar?.thumbnail_url || '/img/sk-logo.svg'}
                alt={`${user.name || 'User'}'s Avatar`}
                className="rounded-circle me-2"
                style={{ width: '30px', height: '30px' }}
            />
            <span>{user.name || 'Неизвестный пользователь'}</span>
        </div>
    );
})}

                </div>
                <button type="submit" className="btn btn-primary w-100">Создать диалог</button>
            </form>
        </div>
    );
};

export default NewDialogForm;
