import React, { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { useSelector } from "react-redux";
import ReusableModal from "./ReusableModal";

const SubscribePopover = ({
    usersWhoSubscribed,
    target,
    handleSubscribePopoverChange,
}) => {
    const [popperRef, setPopperRef] = useState(null);
    const [showPopover, setShowPopover] = useState(false);
    const [arrowElement, setArrowElement] = useState(null);
    const [filtredUsers, setFiltredUsers] = useState([]);
    const [showReusableModal, setShowReusableModal] = useState(false);
    const targetRef = useRef(target);
    const { styles, attributes } = usePopper(targetRef.current, popperRef, {
        placement: "top",
        modifiers: [
            { name: "arrow", options: { element: arrowElement } },
            { name: "offset", options: { offset: [0, 5] } },
        ],
    });
    const [content, setContent] = useState("");
    const users = useSelector((state) => state.users?.users);

    useEffect(() => {
        const filtredUsers = users?.filter((user) =>
            usersWhoSubscribed?.includes(user.id)
        );
        if (filtredUsers?.length) {
            setFiltredUsers(filtredUsers);
        }
    }, [users, usersWhoSubscribed]);

    const handlePopoverToggle = () => {
        setShowPopover(!showPopover);
    };

    const handleClose = () => {
        setShowReusableModal(false);
        handleSubscribePopoverChange(false);
    };

    const handleClick = () => {
        const contentJSX = filtredUsers.map((user) => (
            <div key={user.id} className="d-flex align-items-center mb-2">
                <div className="d-flex flex-column align-items-center">
                    <div>
                        <img
                            src={user.avatar?.medium_url || "/img/sk-logo.svg"}
                            alt="avatar"
                            className="rounded-circle object-fit-cover"
                            style={{ width: "100px", height: "100px" }}
                        />
                    </div>
                    <a
                        className="text-truncate link-underline link-underline-opacity-0 link-secondary link-underline-opacity-100-hover"
                        style={{ width: "100px" }}
                        href={`/user/${user.id}`}
                    >
                        <span>{user.name}</span>
                    </a>
                </div>
            </div>
        ));

        setContent(<div className="d-flex flex-wrap gap-4">{contentJSX}</div>);
        setShowReusableModal(true);
    };

    return (
        <>
            {!showReusableModal ? (
                <div
                    className={`bs-popover-auto popover ${
                        showPopover ? "show" : ""
                    }`}
                    style={styles.popper}
                    {...attributes.popper}
                    ref={setPopperRef}
                    onClick={handleClick}
                    role="button"
                >
                    <div
                        className="popover-arrow"
                        ref={setArrowElement}
                        style={styles.arrow}
                    ></div>
                    {filtredUsers.length ? (
                        <div className="d-flex gap-2 py-2 px-3">
                            {filtredUsers.length < 4 ? (
                                filtredUsers.map((user) => (
                                    <div key={user.id}>
                                        <img
                                            className="rounded-circle w-32 h-32 object-fit-cover"
                                            src={
                                                user.avatar?.thumbnail_url ||
                                                "/img/sk-logo.svg"
                                            }
                                        />
                                    </div>
                                ))
                            ) : (
                                <>
                                    {filtredUsers
                                        .filter((u, i) => i < 3)
                                        .map((user) => (
                                            <div key={user.id}>
                                                <img
                                                    className="rounded-circle w-32 h-32 object-fit-cover"
                                                    src={
                                                        user.avatar
                                                            ?.thumbnail_url ||
                                                        "/img/sk-logo.svg"
                                                    }
                                                />
                                            </div>
                                        ))}
                                    <div className="rounded-circle w-32 h-32 bg-gray-3 d-flex align-items-center justify-content-center">
                                        <span>{`+${
                                            filtredUsers.length - 3
                                        }`}</span>
                                    </div>
                                </>
                            )}
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            ) : (
                <ReusableModal
                    title="Подписчики"
                    show={showReusableModal}
                    footer={false}
                    content={content}
                    onClose={handleClose}
                />
            )}
        </>
    );
};

export default SubscribePopover;
