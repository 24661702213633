import React, { useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PushActive = ({ data }) => {
    const { message, user, id, link } = data;

    // Проверка, если user не существует, то выводим ошибку
    if (!user) {
        console.error("No user data found in notification:", data);
        return null;
    }




    // Формируем сообщение для уведомления
    const notificationMessage = `${message || ""}`;

    // Рендер аватара или SVG
    const renderAvatar = () => {
        if (user.svg) {
            return (
                <img
                    src={user.svg}
                    alt="Notification Icon"
                    style={{ width: "48px", height: "48px", objectFit: "cover" }}
                />
            );
        } else if (user.avatar) {
            return (
                <img
                    src={`/${user.avatar}`}
                    alt={user.name}
                    style={{ width: "48px", height: "48px", borderRadius: "50%", objectFit: "cover" }}
                />
            );
        } else {
            return (
                <div
                    style={{
                        width: "48px",
                        height: "48px",
                        backgroundColor: user.color || "#ccc",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                    }}
                >
                    <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                        {user.short_name || user.name?.charAt(0) || "?"}
                    </span>
                </div>
            );
        }
    };

    // Используем useEffect, чтобы отобразить уведомление
    useEffect(() => {
        toast(
            <div style={{ textDecoration: "none", color: "inherit" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {renderAvatar()}
                    <div style={{ marginLeft: "10px" }}>
                       <div>{notificationMessage}</div>
                        {link && (
                            <div>
                                <a href={link} style={{ textDecoration: "none", color: "#007bff" }}>
                                    Перейти по ссылке
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div>,
            {
                toastId: id,
                position: "bottom-left",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 60,
                hideProgressBar: false,
                newestOnTop: true,
            }
        );
    }, [data]);

    return null; // Этот компонент не рендерит ничего в DOM
};

export default PushActive;
