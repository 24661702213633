import BadgeCard from "./BadgeCard";
import ItemListInModal from "./ItemListInModal";

const BadgesListInModal = ({
    onBadgeClick,
    onBtnClick,
    isSecondStepRequired,
    userId,
    action,
}) => (
    <ItemListInModal
        itemsSelector={(state) => state?.extraTypes?.extraTypes?.Badge}
        ItemCard={({ item, onItemClick, selectedItemId }) => (
            <BadgeCard
                badge={item}
                onBadgeClick={onItemClick}
                selectedBadgeId={selectedItemId}
            />
        )}
        onItemClick={onBadgeClick}
        isSecondStepRequired={isSecondStepRequired}
        onActionClick={onBtnClick}
        userId={userId}
        action={action}
    />
);

export default BadgesListInModal;
