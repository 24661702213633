import React, { useEffect, useState } from "react";
import CommentComponent from "./Comment/CommentComponent";
import UserLogo from "./UserLogo";
import BadgeComponent from "./BadgeComponent";
import PostBodyComponent from "./PostBodyComponent";
import AttachmentsComponent from "../Trash/AttachmentsComponent";
import LikeButton from "./LikeButton";
import CommentsButton from "./Comment/CommentsButton";
import "bootstrap";
import CommentInput from "./Comment/CommentInput";
import EyeSVG from "../Icons/EyeSVG";
import FilePreview from "./FilePreview";
import { getFileType } from "../../functions";
import PinButton from "./PinButton";
import { useDispatch, useSelector } from "react-redux";
import EditIconSVG from "../Icons/EditIconSVG";
import { setData } from "../../redux/postFieldSlice";
import { editPost } from "../../redux/stenasSlice";

function StenaComponent({ stena, model, onOpenModal, textTruncate = true }) {
    // console.log(stena);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.users.currentUser);
    const [isCommentsExpanded, setIsCommentsExpanded] = useState(false);
    const [isChildCommentsExpanded, setIsChildCommentsExpanded] =
        useState(false);
    const [replyId, setReplyId] = useState(null);
    const [startingInputValue, setStartingInputValue] = useState("");
    const [imageAttachments, setImageAttachments] = useState([]);
    const [fileAttachments, setFileAttachments] = useState([]);
    const [isPinned, setIsPinned] = useState(stena.is_banner || 0);
    const fullName = stena.user.name;
    const nameParts = fullName.split(" ");

    const lastName = nameParts[0]; // Фамилия
    const firstName = nameParts[1]; // Имя
    const patronymic = nameParts[2]; // Отчество (если есть)

    const formattedName = patronymic
        ? `${lastName} ${firstName.charAt(0)}. ${patronymic.charAt(0)}.`
        : `${lastName} ${firstName.charAt(0)}.`;
    const handleCommentsToggle = () => {
        // Toggle the state to expand/collapse comments
        setIsCommentsExpanded(!isCommentsExpanded);

        // You can also implement the logic to load comments here if needed
        // Example: fetch(`/api/comments/${model}/${data.id}`)
    };

    const customFontStyle = {
        "@media (max-width: 768px)": {
            fontSize: "14px",
        },
    };

    const isPostOwner = stena.user.id === user.id;

    function handleChildCommentsToggle(id) {
        setIsChildCommentsExpanded(!isChildCommentsExpanded);
        setReplyId(id);
        const authorName = stena.comments.find((c) => c.id === id).author.name;
        setStartingInputValue(
            `<a href="#comment_${id}" role="button" class="reply-click">${authorName},&nbsp;</a>`
        );
    }

    useEffect(() => {
        const imageAttachments = [];
        const fileAttachments = [];
        if (stena.attachments?.length) {
            stena.attachments.map((attachment) => {
                if (getFileType(attachment) === "media") {
                    imageAttachments.push(attachment);
                } else {
                    fileAttachments.push(attachment);
                }
            });
        }
        if (imageAttachments.length) {
            setImageAttachments(imageAttachments);
        }
        if (fileAttachments.length) {
            setFileAttachments(fileAttachments);
        }
    }, [stena]);

    const handleEditClick = () => {
        dispatch(setData(stena)); // Передаем данные в Redux
    };

    const handlePinClick = async (e) => {
        e.preventDefault();
        const newData = {
            ...stena,
            is_banner: !isPinned ? 1 : 0,
        };
        try {
            // Получаем результат выполнения
            const result = await dispatch(editPost({ data: newData }));

            // Проверяем, была ли ошибка
            if (editPost.fulfilled.match(result)) {
                setIsPinned(!isPinned);
            } else if (editPost.rejected.match(result)) {
                throw (
                    result.payload || "Произошла ошибка при обновлении данных."
                );
            }
        } catch (error) {
            console.error("Произошла ошибка:", error);
            alert("Произошла ошибка при обновлении данных.");
        }
    };

    return (
        <div
            className="bg-white rounded d-flex flex-column gap-2 justify-content-between p-md-4 p-3"
            data-post-id={stena.id}
        >
            <div className="bg-white rounded d-flex flex-column gap-2 justify-content-between">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center gap-2">
                        <UserLogo user={stena.user} />
                        <div className="d-flex flex-column d-none d-md-block">
                            <a
                                href={`/user/${stena.user.id}`}
                                className="text-18 link-underline d-none d-md-block link-underline-opacity-0 link-secondary link-underline-opacity-100-hover"
                            >
                                {stena.user.name ?? " Бот"}
                            </a>
                            <a
                                href={`/user/${stena.user.id}`}
                                className="text-18 link-underline d-md-none link-underline-opacity-0 link-secondary link-underline-opacity-100-hover"
                            >
                                {formattedName ?? " Бот"}
                            </a>
                            <a className="text-14 link-underline link-underline-opacity-0 link-gray-6-text link-underline-opacity-100-hover">
                                {stena.formattedDateTime}
                            </a>
                        </div>
                        <div className="d-flex flex-column d-block d-md-none">
                            <a
                                href={`/user/${stena.user.id}`}
                                className="text-14 link-underline d-none d-md-block link-underline-opacity-0 link-secondary link-underline-opacity-100-hover"
                            >
                                {stena.user.name ?? " Бот"}
                            </a>
                            <a
                                href={`/user/${stena.user.id}`}
                                className="text-14 link-underline d-md-none link-underline-opacity-0 link-secondary link-underline-opacity-100-hover"
                            >
                                {formattedName ?? " Бот"}
                            </a>
                            <a className="text-14 link-underline link-underline-opacity-0 link-gray-6-text link-underline-opacity-100-hover">
                                {stena.formattedDateTime}
                            </a>
                        </div>
                    </div>
                    <BadgeComponent extraTypes={stena.extra_types} />
                </div>
                <div className="d-flex flex-column gap-2">
                    <div>
                        <PostBodyComponent
                            data={stena}
                            attachmentsCount={stena.attachments?.length}
                            onOpenModal={onOpenModal}
                            textTruncate={textTruncate}
                        />
                        {/* {stena.attachments && stena.attachments.length > 0 && (
                            <AttachmentsComponent
                                attachments={stena.attachments}
                            />
                            
                            <FilePreview files={stena.attachments} />
                        )} */}
                        <div className="d-flex flex-column gap-2">
                            {imageAttachments.length ? (
                                <AttachmentsComponent
                                    attachments={imageAttachments}
                                />
                            ) : (
                                ""
                            )}
                            {fileAttachments.length ? (
                                <FilePreview files={fileAttachments} />
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center gap-3">
                            <LikeButton model="Stena" data={stena} />
                            <CommentsButton
                                model="Stena"
                                data={stena}
                                totalCommentsCount={stena.comments?.length}
                                isCommentsExpanded={isCommentsExpanded}
                                handleCommentsToggle={handleCommentsToggle}
                            />
                            {user.role_id === 1 && (
                                <PinButton
                                    handlePinClick={handlePinClick}
                                    isPinned={isPinned}
                                />
                            )}
                        </div>
                        <div className="d-flex gap-1 align-items-center">
                            {!stena.transaction && isPostOwner && (
                                <div
                                    className="d-flex align-items-center gap-1"
                                    onClick={handleEditClick}
                                >
                                    <EditIconSVG />
                                </div>
                            )}
                            <div className="d-flex align-items-center gap-1">
                                <EyeSVG />
                                <span className="text-muted text-14">
                                    {stena.view}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isCommentsExpanded && (
                <div className="border-top border-gray-3 border-1">
                    <div className="d-flex flex-column pt-1 ms-0 mb-0 gap-2">
                        <div></div>
                        {stena.comments?.length
                            ? stena.comments
                                  .filter((comment) => !comment.parent_id)
                                  .map((comment) => (
                                      <CommentComponent
                                          key={comment.id}
                                          comment={comment}
                                          stena={stena}
                                          children={stena.comments.filter(
                                              (child) =>
                                                  child.parent_id === comment.id
                                          )}
                                          handleCommentsToggle={
                                              handleChildCommentsToggle
                                          }
                                      />
                                  ))
                            : ""}
                        {isChildCommentsExpanded ? (
                            <div className="ms-4 ps-3 mb-3">
                                <div className="d-flex flex-column">
                                    <CommentInput
                                        postId={stena.id}
                                        commentId={replyId}
                                        startingInputValue={startingInputValue}
                                        setIsCommentsExpanded={
                                            setIsChildCommentsExpanded
                                        }
                                    />
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                        <CommentInput postId={stena.id} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default StenaComponent;
