import React, { useState } from "react";
import DialogsList from "./DialogsList"; // Компонент для списка диалогов
import MessagesList from "./MessagesList"; // Компонент для списка сообщений
import NewDialogForm from "../NewDialogForm"; // Компонент формы для создания нового диалога

const ChatContainer = () => {
    const [selectedDialog, setSelectedDialog] = useState(null);
    const [showNewDialogForm, setShowNewDialogForm] = useState(false);

    // Функция для обработки выбора диалога
    const handleSelectDialog = (dialog) => {
        setSelectedDialog(dialog);
        setShowNewDialogForm(false); // Скрыть форму создания диалога при выборе другого диалога
    };

    // Функция для обработки создания нового диалога
    const handleDialogCreated = (newDialog) => {
        setSelectedDialog(newDialog); // Выбрать новый диалог
        setShowNewDialogForm(false); // Скрыть форму создания диалога
    };

    return (
        <div className="container-fluid d-flex flex-column">
            <div className="row flex-grow-1">
                {/* Список диалогов */}
                <div
                    className="col-md-4 col-12 d-flex flex-column border-end p-0 bg-light"
                >
                    <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                        <button
                            onClick={() => setShowNewDialogForm(true)}
                            className="btn btn-primary w-100"
                        >
                            Новый диалог
                        </button>
                    </div>
                    <div className="overflow-auto flex-grow-1" style={{ height: 'calc(100vh - 60px)' }}>
                        <DialogsList onSelectDialog={handleSelectDialog} />
                    </div>
                </div>

                {/* Список сообщений */}
                <div className="col-md-8 col-12 d-flex flex-column p-0">
                    <div
                        className="overflow-auto flex-grow-1 d-flex flex-column bg-white"
                        style={{ height: 'calc(100vh - 60px)' }}
                    >
                        {showNewDialogForm ? (
                            <NewDialogForm onDialogCreated={handleDialogCreated} />
                        ) : selectedDialog ? (
                            <MessagesList
                                dialog={selectedDialog}
                                dialogId={selectedDialog.id}
                            />
                        ) : (
                            <div className="text-center m-auto">
                                <p className="mb-3">Выберите диалог или создайте новый.</p>
                                <button
                                    onClick={() => setShowNewDialogForm(true)}
                                    className="btn btn-primary"
                                >
                                    Создать новый диалог
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatContainer;
